import { Box, chakra } from "@chakra-ui/react";

export const CustomHeader = chakra("h2", {
  baseStyle: {
    color: "darkPrimary",
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "0",
    marginBottom: "10px",
  },
});

export const CustomServiceBox = chakra(Box, {
  baseStyle: {
    height: "65px",
    width: "65px",
    borderRadius: "7.5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
});

export const CustomIcon = chakra(Box, {
  baseStyle: {
    height: "20px",
    width: "20px",
    color: "lightPrimary",
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "14px",
    textAlign: "center",
    margin: "5px",
  },
});
