import { useQuery, useMutation } from "react-query";
import { getJSON, putJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getParentalControlDictionary = async () => getJSON(endpoints.parentalControlDictionary);

export const restructureParentalControlOptions = (options: any[]) => {
    return options ? Object.values(options) : [];
};

export const useParentalControlOptions = () => {
    const { data, isLoading, error } = useQuery("parentalControlOptions", getParentalControlDictionary, {
        staleTime: Infinity,
    });

    return {
        isLoading,
        data: data?.data || [],
        error,
    };
};
