import { chakra, Tab, Flex, Image } from "@chakra-ui/react";

export const CustomLogo = chakra(Image, {
  baseStyle: {
    maxHeight: '50px',
    width: 'auto'
    // height: '50px',
    // width: 'auto',
    // maxHeight: '95%',
    // maxWidth: '95%',
  },
});

export const CustomTab = chakra(Tab, {
  baseStyle: {
    fontSize: "19px",
    height: "100px",
    color: "darkerPrimary",
    _selected: {
      borderBottom: "5px solid lightPrimary",
    },
  },
});

export const TopBarWrapper = chakra(Flex, {
  baseStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
});
