import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getServicesList = async () => getJSON(endpoints.services.list);

export const useServicesList = () => {
  const { data, isLoading, error } = useQuery("servicesList", getServicesList, {
    staleTime: Infinity,
  });

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
