import {useQuery} from 'react-query';
import {getJSON} from '../api/http';
import {endpoints} from '../api/endpoints';
import {restructureDate} from '../shared/utils';

interface IBEContract {
    active: string;
    actual_contractexpiredate: string;
    blocked: string;
    contractdate: string;
    contractexpiredate: string;
    contract_number: string;
    html: string;
    status_desc: string;
}

export const restructureContractDetails = ({
                                               active,
                                               actual_contractexpiredate,
                                               blocked,
                                               contractdate,
                                               contractexpiredate,
                                               contract_number,
                                               html,
                                               status_desc,
                                           }: IBEContract) => ({
    actual_contractexpiredate: actual_contractexpiredate,
    blocked: blocked,
    contractexpiredate: contractexpiredate,
    date: restructureDate(contractdate),
    html,
    name: contract_number,
    status: active,
    status_desc,
});

const getContractDetails = async (id: string) =>
    getJSON(endpoints.contracts.details + '/' + id);

export const useContractDetails = (id: string) => {
    const {data, isLoading, error} = useQuery(
        'ContractDetails' + id,
        () => getContractDetails(id),
        {
            staleTime: Infinity,
        }
    );

    return {
        isLoading,
        data: restructureContractDetails(data?.data || {}),
        error,
    };
};
