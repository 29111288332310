import {useQuery} from "react-query";
import {getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";

const getGlobalDamages = async () => getJSON(endpoints.globalDamages);

export const useGlobalDamages = () => {
    const {data, isLoading, error} = useQuery("globalDamages", getGlobalDamages, {
        staleTime: Infinity,
    });

    return {
        isLoading,
        data: {list: data?.data.list, totalDamagesLength: data?.data.total} || {},
        error,
    };
};
