import { Box, chakra, Image } from "@chakra-ui/react";
// import bannerImage from "../../../img/bannerDashboard/digital.png";

export const CustomHeader = chakra("div", {
  baseStyle: {
    color: "white",
    fontSize: "24px", // Large size
    fontWeight: "600",
  },
});

export const CustomBox = chakra(Box, {
  baseStyle: {
    height: "150px",
    width: "100%",
    backgroundColor: "black21",
  },
});

// export const CustomGraph = chakra("div", {
//   baseStyle: {
//     height: "150px",
//     width: "922px",
//     backgroundImage: `url(${bannerImage})`,
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "cover",
//   },
// });

export const CustomBannerImage = chakra(Image, {
  baseStyle: {
    objectFit: "cover",
    height: "full",
    position: "absolute",
    top: "0px",
    right: "70px",
  },
});
