import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "../Card/chakra";

export interface IPageHeader {
  title?: string;
  buttonText?: string;
  onlyMobile?: boolean;
}

export const PageHeader: FC<IPageHeader> = ({
  title,
  buttonText,
  onlyMobile = false,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Flex
        justify="center"
        align="center"
        position="relative"
        display={["flex", "flex", "none"]}
      >
        <IconButton
          variant="ghost"
          aria-label="Powrót"
          position="absolute"
          left="0"
          onClick={goBack}
          icon={<ChevronLeftIcon fontSize="22px" />}
        />
        {title && (
          <Text fontSize="18px" color="black" fontWeight={600} py="15px">
            {title}
          </Text>
        )}
      </Flex>
      {!onlyMobile && (
        <Flex
          justify="center"
          position="relative"
          display={["none", "none", "flex"]}
        >
          <Button
            size="md"
            aria-label="Show Details"
            color="darkPrimary"
            borderColor="lightPrimary"
            position="absolute"
            left="0"
            onClick={goBack}
            leftIcon={<ChevronLeftIcon />}
          >
            {buttonText}
          </Button>
          {title && <CustomHeader>{title}</CustomHeader>}
        </Flex>
      )}
    </>
  );
};
