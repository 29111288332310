import {useMutation, useQuery} from "react-query";
import {getJSON, postJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {useState} from "react";
import {LocalStorageService} from "../services/LocalStorageService";

const getRodo = async () =>
    getJSON(endpoints.rodo);

const putUserChangePassword = () =>
    postJSON(endpoints.rodo, {});

export const useRodo = (successCallback?: Function) => {
    const {setToken} = LocalStorageService(),
        [errors, setErrors] = useState<string[]>([]),

        {mutate} = useMutation(
            putUserChangePassword,
            {
                onSuccess: () => {
                    setToken('1', 'rodo')
                    if (successCallback) {
                        successCallback();
                    }
                },
                onError: (error: any) => {
                    setErrors(Object.values(error?.data.errors || {}));
                },
                onSettled: () => {
                },
            }
        ),

        {data, isLoading, error} = useQuery(
            "rodo",
            () => getRodo(),
            {
                staleTime: Infinity,
            }
        );

    return {
        acceptRodo: mutate,
        isLoading,
        data: data?.data,
        error,
    };
};
