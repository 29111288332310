export const colors = {
  lightPrimary: "#00B7FF",
  lighterPrimary: "#4DCCFF",
  darkPrimary: "#1B378C",
  darkerPrimary: "#262F47",
  backgroundPrimary: "#F6FAFD",

  white: "#FFFFFF",

  gray60: "#999999",
  lightGray: "#7F8185",
  lighterGray: "#EFF3FB",
  darkGray: "#D6E0F5",
  darkerGray: "#6C6C6C",

  borderGray: "#D8D8D8",
  detailGray: "#E5E8F0",

  black: "#000000",
  basicBlack: "#222222",
  mobileBlack: "#4D4D4D",
  black21: "#262F47",
  hamburgerMobileBlack: "#1F232E",

  darkRed: "#E62D2D",
  darkGreen: "#34AA44",
};

// export const chakraColors = {
//   blue: {
//     100: "#4DCCFF", // Lighter Blue
//     300: "#00B7FF", // Light Blue

//     700: "#1B378C", // Contact Blue
//     900: "#262F47", // Dark Blue
//   },
// };
