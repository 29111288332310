import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getInvoicesYearDict = async () => getJSON(endpoints.invoices.dict.year);

export const useInvoicesYearDict = () => {
  const { data, isLoading, error } = useQuery(
    "InvoicesYearDict",
    getInvoicesYearDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const getInvoicesSettledDict = async () =>
  getJSON(endpoints.invoices.dict.settled);

export const useInvoicesSettledDict = () => {
  const { data, isLoading, error } = useQuery(
    "InvoicesSettledDict",
    getInvoicesSettledDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
