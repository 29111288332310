declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_KILOBIT_TO_MEGABIT_DIVISOR: string,
    REACT_APP_USE_USER_CODE: string
}


export const env: EnvType = { ...process.env, ...window['env'] }