import {stringify} from "querystring";
import {useQuery} from "react-query";
import {endpoints} from "../api/endpoints";
import {getJSON} from "../api/http";

const getContractTerminationDict = async () =>
    getJSON(endpoints.contracts.dict.contractEndType);

export const useContractTerminationDict = () => {
    const {data, isLoading, error} = useQuery(
        "contractTerminationDict",
        getContractTerminationDict,
        {
            staleTime: Infinity,
        }
    );

    return {
        isLoading,
        data: data?.data || [],
        error,
    };
};

const getContractDeclaration = async (id: string, stringParams: string) =>
    getJSON(
        [
            endpoints.contracts.dict.declaration.replaceAll("{id}", id),
            stringParams && "?",
            stringParams,
        ].join("")
    );

export const useContractDeclaration = (type_id: string, type: string) => {
    let urlParams = "";

    if (type_id) urlParams += stringify({type_id, type});

    const {data, isLoading, error} = useQuery(
        "contractDeclaration_" + type,
        () => getContractDeclaration(type_id, urlParams)
    );

    return {
        isLoading,
        data: data?.data || [],
        error,
    };
};
