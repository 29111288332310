import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { FC } from "react";
import {
  DOTS,
  IPaginationProps,
  usePagination,
} from "../../../hooks/usePagination";

interface IPagination {
  onPageChange: (value: number) => void;
}

export const Pagination: FC<IPaginationProps & IPagination> = ({
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <Flex mt={"10px"} justify={"center"}>
      {currentPage !== 1 && (
        <Button
          mx={"5px"}
          color="darkPrimary"
          borderColor="darkPrimary"
          onClick={onPrevious}
          variant="outline"
        >
          <ChevronLeftIcon />
        </Button>
      )}

      {paginationRange &&
        paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <Button
                key={pageNumber + index}
                mx={"5px"}
                color="darkPrimary"
                borderColor="darkPrimary"
                variant="disabled"
              >
                &#8230;
              </Button>
            );
          }

          return (
            <Button
              key={pageNumber}
              mx={"5px"}
              color="darkPrimary"
              borderColor="darkPrimary"
              onClick={() => onPageChange(Number(pageNumber))}
              variant={currentPage === pageNumber ? "solid" : "outline"}
            >
              {pageNumber}
            </Button>
          );
        })}

      {currentPage !== lastPage && (
        <Button
          mx={"5px"}
          colorScheme="blue"
          onClick={onNext}
          variant="outline"
        >
          <ChevronRightIcon />
        </Button>
      )}
    </Flex>
  );
};
