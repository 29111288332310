import { useMutation } from "react-query";
import { extendedPostJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

const postTelevisionPackageActivation = (data: any) =>
  extendedPostJSON(endpoints.television.subscriptions, data);

export const useTelevisionPackageActivation = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isSuccess, isError, reset } = useMutation(
    postTelevisionPackageActivation,
    {
      onSuccess: () => {},
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => {},
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    errors,
    televisionPackageActivation: mutate,
    resetForm: reset,
  };
};
