import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getPaymentsDetails = async () => getJSON(endpoints.user.payment.details);

export const usePaymentsDetails = () => {
  const { data, isLoading, error } = useQuery(
    "paymentsDetails",
    () => getPaymentsDetails(),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
