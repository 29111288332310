import { FC } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Card } from "../../Card";
import { CustomedButton, Detail, PaymentModal, StatusBadge } from "../..";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {useFirm} from "../../../../hooks/useFirm";
import {daysDifference, monthsDifference, restructureDate} from "../../../utils";

interface ILastPaymentCard {
  id: string;
  date: string;
  documentNumber: string | null;
  status: string;
  toPay: string;
}

export const LastPaymentCard: FC<ILastPaymentCard> = ({
  id,
  date,
  documentNumber,
  status,
  toPay,
}) => {
  const navigate = useNavigate();

  const firm = useFirm();

  const { ebok_payments } = firm.data;

  return (
    <Card
      title={(monthsDifference(date) < 0) ? 'Najstarsza zaległość' : 'Bieżąca płatność'}
      extraContent={
        <Button
          backgroundColor="lighterGray"
          size="sm"
          rightIcon={<FaAngleRight />}
          onClick={() => {
            navigate("/payment");
          }}
        >
          Pokaż moje faktury
        </Button>
      }
    >
      <Flex>
        <Detail title="Numer dokumentu" textSize="24px" color="black">
          {documentNumber || "Brak danych"}
        </Detail>
      </Flex>
      {documentNumber !== null && (
        <>
          <Flex justifyContent="space-between">
            <Detail title="Termin płatności" textSize="24px" color="black">
              {restructureDate(date) || "Brak danych"}
            </Detail>
            <Box mr="60px">
              <Detail title="Do zaplaty" textSize="24px"
                      color={daysDifference(date) ? 'darkRed' : 'lightPrimary'}>
                {(toPay || 0)} zł
              </Detail>
            </Box>
          </Flex>

          <Text fontSize="14px" color="lightGray" textTransform="uppercase">
            Status:
          </Text>
          <Flex flexDir="row" justifyContent="space-between" align="center">
            {parseInt(toPay) === 0 ? (
              <StatusBadge label="Opłacona" status="active" />
            ) : (
              <StatusBadge label="Nieopłacona" status="inactive" />
            )}
            {ebok_payments ? (
            <PaymentModal
              button={
                <CustomedButton
                  label="Opłać ON-LINE"
                  disabled={parseInt(toPay) === 0}
                />
              }
            />
              ) : ('') }
          </Flex>
        </>
      )}
    </Card>
  );
};
