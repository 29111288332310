import { Box, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import { Card, CustomedButton, Detail, PaymentModal, StatusBadge } from "../..";
import { useInvoicesLast } from "../../../../hooks/useInvoicesLast";
import {daysDifference, monthsDifference, restructureDate} from "../../../utils";
import {useFirm} from "../../../../hooks/useFirm";

export const FinanceLastPaymentCard: FC = () => {
  const lastInvoice = useInvoicesLast().data;
  const isVisible = lastInvoice?.TrN_NumerPelny ? true : false;

    const firm = useFirm();

    const { ebok_payments } = firm.data;

  return (
    <Card title={(monthsDifference(lastInvoice?.TrN_Termin_UTS) < 0) ? 'Najstarsza zaległość' : 'Bieżąca płatność'}>
      <Flex flexWrap={"wrap"}>
        <Detail
          title="Numer faktury"
          textSize="24px"
          color="black"
          margin="0 70px 20px 0"
        >
          {lastInvoice?.TrN_NumerPelny || "Brak danych"}
        </Detail>
        {isVisible && (
          <Flex flexDir="column" mb="20px">
            <Text fontSize="14px" color="lightGray" textTransform="uppercase">
              Status:
            </Text>
            {parseInt(lastInvoice?.settled_left) === 0 ? (
              <StatusBadge status="active" label="Opłacona" />
            ) : (
              <StatusBadge status="inactive" label="Nieopłacona" />
            )}
          </Flex>
        )}
      </Flex>
      {isVisible && (
        <Flex justifyContent="space-between" flexWrap={"wrap"}>
          <Detail
            title="Termin płatności"
            textSize="39px"
            color="black"
            margin="0 10px 0 0"
          >
            {restructureDate(lastInvoice?.TrN_Termin_UTS) || "Brak danych"}
          </Detail>
          <Detail
            title="Do zaplaty"
            textSize="39px"
            color={daysDifference(lastInvoice?.TrN_Termin_UTS) ? 'darkRed' : 'lightPrimary'}
            margin="0 10px 0 0"
          >
            {(lastInvoice.settled_left || 0)} zł
          </Detail>

          <Box alignSelf="flex-end" mt="20px" mb="12px">
            {ebok_payments ? (
            <PaymentModal
              button={
                <CustomedButton
                  label="Opłać ON-LINE"
                  disabled={parseInt(lastInvoice?.settled_left) === 0}
                />
              }
            />
            ) : ''}
          </Box>
        </Flex>
      )}
    </Card>
  );
};
