import { FC } from "react";
import {
  Table,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Flex,
  Box,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";
import { useNavigate } from "react-router-dom";
import { convertFromKilobitToMegabit } from "../../../../hooks/useInternetNode";

export interface IInternetConnection {
  ipAddress: string;
  macAddress: string;
  download: string;
  upload: string;
  id?: string;
}

export interface IInternetTable {
  connections: IInternetConnection[];
}

export const InternetTable: FC<IInternetTable> = ({ connections }) => {
  const navigate = useNavigate();

  return (
    <Table variant="simple">
      <CustomedTableHead>
        <Tr>
          <Th>Adres IP</Th>
          <Th>Adres MAC</Th>
          <Th>Prędkość (DOWNLOAD)</Th>
          <Th>Prędkość (UPLOAD)</Th>
          <Th>{/*Przycisk*/}</Th>
        </Tr>
      </CustomedTableHead>

      <CustomedTableBody>
        {connections.length ? (
          connections.map(({ id, ipAddress, macAddress, download, upload }) => (
            <Tr key={ipAddress + macAddress}>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {ipAddress}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {macAddress}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="lightPrimary">
                  {convertFromKilobitToMegabit(download)} Mbit/s
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="lightPrimary">
                  {convertFromKilobitToMegabit(upload)} Mbit/s
                </Text>
              </Td>
              <Td>
                <IconButton
                  variant="outline"
                  size="md"
                  aria-label="Show Details"
                  color="lightPrimary"
                  borderColor="lightPrimary"
                  icon={<ChevronRightIcon />}
                  onClick={() => {
                    navigate(`node/${id}`);
                  }}
                />
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </CustomedTableBody>

      <MobileTableBody>
        {connections.length ? (
          connections.map(({ id, ipAddress, macAddress, download, upload }) => (
            <Tr key={ipAddress + macAddress + "mobile"}>
              <Td p="5px 0">
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Box>
                    <Text fontSize="14px">Adres IP</Text>
                    <Text fontSize="14px" fontWeight={600}>
                      {ipAddress}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="14px">{"Download/Upload"}</Text>
                    <Text
                      color="lightPrimary"
                      fontSize="14px"
                      fontWeight={600}
                    >{`${Number(download) / 1024} / ${Number(upload) / 1024} Mbit/s`}</Text>
                  </Box>
                </Flex>
              </Td>
              <Td p="0px">
                <Flex justifyContent="flex-end">
                  <ChevronRightIcon
                    color="darkPrimary"
                    fontSize="30px"
                    fontWeight="600"
                    onClick={() => {
                      navigate(`node/${id}`);
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
          </Tr>
        )}
      </MobileTableBody>
    </Table>
  );
};
