import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CustomedButton, Detail } from "../..";
import { useContractsList } from "../../../../hooks/useContractsList";
import { restructureDate } from "../../../utils";
import { env } from "../../../../env";

export interface IMyAccountCard {
  userNumber: string;
}

export interface IContractExpiration {
  actual_contractexpiredate: string;
}

const findFirstContractExpirationDate = (list: IContractExpiration[]) =>
  restructureDate(
    list.sort((a, b) => {
      return (
        new Date(a.actual_contractexpiredate).getTime() -
        new Date(b.actual_contractexpiredate).getTime()
      );
    })[0]?.actual_contractexpiredate
  );

export const MyAccountCard: FC<IMyAccountCard> = ({ userNumber }) => {
  const navigate = useNavigate();
  const { data } = useContractsList();
  const expireDate = findFirstContractExpirationDate(data?.list || []);

  return (
    <Card title="Moje konto">
      <Detail title={(env.REACT_APP_USE_USER_CODE === 'true') ? "Kod Klienta" : "Nr Klienta"} textSize="24px" color="lightPrimary">
        {userNumber}
      </Detail>
      <Detail title="Czas obowiązywania umowy" textSize="24px" color="black">
        {expireDate === "0" ? "Nieokreślony" : expireDate}
      </Detail>
      <CustomedButton
        label="Zobacz więcej"
        onClick={() => {
          navigate("/payment");
        }}
      />
    </Card>
  );
};
