import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    CloseButton,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {ChangeEvent, cloneElement, FC, ReactElement, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    CustomedButton,
    CustomedSelect,
    CustomedTextArea,
    FormSuccess,
} from '../..';
import {useContractDeclarationForm} from '../../../../hooks/useContractDeclarationForm';
import {useContractDeclaration} from '../../../../hooks/useContractDict';
import {afterMonths, timestampToDateString} from '../../../utils';
import {CustomedInput} from '../../CustomedInput';
import {useContractDeclarationSms} from '../../../../hooks/useContractDeclarationSms';

interface ISms {
    pin?: string;
}

export interface IContractTerminationModal {
    button?: ReactElement;
    contractDate?: string;
    contractNumber?: string;
}

const DEFAULT_STATE = {
    expiredate: '',
    reason: '',
    body: '',
    type: 'contract_break',
};

export const ContractTerminationModal: FC<IContractTerminationModal> = ({
                                                                            button,
                                                                            contractDate,
                                                                            contractNumber,
                                                                        }) => {
    let {id} = useParams(),
        declarationText = '';
    const declaration = useContractDeclaration(id || '', 'contract_break'),
        [smsInput, setSmsInput] = useState<ISms>({}),
        [firstSmsSent, setFirstSmsSent] = useState(false),

        {data, isLoading, isSuccess, isError, errors, contractForm, resetForm} = useContractDeclarationForm(),
        contractDeclarationMutation = useContractDeclarationSms(data?.data || '0'),

        {isOpen, onOpen, onClose} = useDisclosure(),
        [inputs, setInputs] = useState({...DEFAULT_STATE, type_id: id}),

        handleInputChange = (key: string, value: string) => {
            setInputs((oldState) => {
                const newState = {...oldState, [key]: value};
                return newState;
            });
        },

        sendSMS = (withPin?: boolean) => {
            contractDeclarationMutation.sms(withPin ? smsInput : {});
        },

        onSubmit = (values: any) => {
            const formValues = {...values};

            if (formValues) {
                if (formValues.body.trim().length > 9) {
                    formValues.reason = formValues.body;
                }

                delete formValues.body;
                contractForm(formValues);
            }
        },

        handleClose = () => {
            setInputs({...DEFAULT_STATE, type_id: id});
            contractDeclarationMutation.resetForm();
            resetForm();
            onClose();
        };

    useEffect(() => {
        if (!isLoading && isSuccess && !firstSmsSent) {
            contractDeclarationMutation.sms({});
            setFirstSmsSent(true);
        }
    }, [firstSmsSent, contractDeclarationMutation, isLoading, isSuccess])

    if (declaration.data && Object.keys(declaration.data).length) {
        declarationText = declaration.data.body;
    }

    return (
        <>
            {button ? (
                cloneElement(button, {onClick: onOpen})
            ) : (
                <CustomedButton centered={true} fontSize="14px" height="35px" label="Kontakt" onClick={onOpen}/>
            )}

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay onClick={handleClose}/>
                <ModalContent minW={["100%", "100%", "500px"]} minH={["100%", "100%", "initial"]} m={["0px", "0px", "60px 0px"]} borderRadius={["0px", "0px", "10px"]}>
                    <ModalHeader color="darkPrimary">
                        Oświadczenie o rezygnacji
                    </ModalHeader>

                    <ModalCloseButton fontSize={["20px", "20px", "12px"]} m={["10px", "10px", "0px"]}/>
                    <ModalBody>
                        {!isSuccess && (
                            <>
                                {isError && (
                                    <Alert status="error" borderRadius="8px" mb="10px">
                                        <AlertIcon/>
                                        <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                                        <AlertDescription mr={2}>
                                            {errors.join(" ")}
                                        </AlertDescription>
                                        <CloseButton position="absolute" right="8px" top="8px" onClick={resetForm}/>
                                    </Alert>
                                )}
                              {contractDeclarationMutation.isError && (
                                  <Alert status="error" borderRadius="8px" mb="10px">
                                    <AlertIcon />
                                    <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                                    <AlertDescription>{contractDeclarationMutation.errors.join(" ")}</AlertDescription>
                                    <CloseButton
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                        onClick={contractDeclarationMutation.resetForm}
                                    />
                                  </Alert>
                              )}
                                <Text fontSize="14px" color="darkerPrimary" mb="20px">
                                    Oświadczam, że z dniem{" "}
                                    <b>{timestampToDateString(new Date().getTime())} </b>
                                    wypowiadam Umowę o świadczenie usług telekomunikacyjnych numer{" "}
                                    <b>{contractNumber}</b> zawartą dnia <b>{contractDate}</b>.
                                </Text>

                                <Flex flexDir="row" justifyContent="space-between" align="center" mb="20px">
                                    <Flex flexDir="column">
                                        <Text fontSize="14px" color="darkerPrimary" fontWeight={600}>
                                            Wnioskowana data rozwiązania umowy:
                                        </Text>
                                        <Text fontSize="12px" color="red" fontWeight={500}>
                                            Wymagane do wysłania oświadczenia.
                                        </Text>
                                    </Flex>

                                    <Box maxW={"200px"}>
                                        <CustomedInput placeholder="Wybierz datę" type="date" min={afterMonths(1)} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            handleInputChange("expiredate", event.target.value);
                                        }}/>
                                    </Box>
                                </Flex>

                                <Text fontSize="14px" color="darkerPrimary" mb="20px" textAlign="justify" dangerouslySetInnerHTML={{
                                    '__html': declarationText.replaceAll('{expiredate}', inputs.expiredate.split('-').reverse().join('-'))
                                }}/>
                                <CustomedSelect label="Prosimy o podanie powodu rezygnacji:" onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    handleInputChange("reason", event.target.value);
                                }} value={inputs.reason} placeholder="Wybierz temat" isDisabled={!!false} margin="0 0 20px 0">
                                    {!declaration.isLoading &&
                                    declaration.data && declaration.data.reason.map(
                                        (value: string, index: number) => (
                                            <option key={index} value={value}>
                                                {value}
                                            </option>
                                        )
                                    )}
                                </CustomedSelect>
                                <CustomedTextArea label="Inny powód" placeholder="Prosimy o wpisanie powodu rezygnacji..." min={10} onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                    handleInputChange("body", event.target.value);
                                }} rows={4}/>
                            </>
                        )}
                        {isSuccess && contractDeclarationMutation.action === "sms_send" && (
                            <>
                                <Text>
                                    Za chwilę otrzymasz kod SMS. Podaj go aby potwierdzić wysłanie wniosku.
                                </Text>
                                <Flex align="center" mt={["0px", "0px", "20px"]} flexDir={["column", "column", "row"]} flexWrap="wrap">
                                    <Box width={["100%", "100%", "150px"]} mr={["0px", "0px", "20px"]}>
                                        <CustomedInput label="Kod SMS" width={["100%", "100%", "200px"]} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setSmsInput({pin: event.target.value});
                                        }}/>
                                    </Box>
                                    <CustomedButton label="Wyślij kod ponownie" backgroundColor="lightPrimary" alignSelf="flex-end" height="35px" width={["100%", "100%", "auto"]} mt="20px"
                                                    onClick={() => sendSMS()} isLoading={contractDeclarationMutation.isLoading && contractDeclarationMutation.action !== "sms_send"}/>
                                </Flex>
                                <CustomedButton label="Potwierdź" backgroundColor="lightPrimary" alignSelf="flex-end" height="35px" width={["100%", "100%", "auto"]} mr={["0px", "0px", "20px", "20px"]}
                                                mt="20px" disabled={
                                    smsInput.pin?.length === undefined ||
                                    smsInput.pin?.length < 1
                                } isLoading={contractDeclarationMutation.isLoading && contractDeclarationMutation.action === "sms_send"} onClick={() => sendSMS(true)}/>
                            </>
                        )}
                        {isSuccess && contractDeclarationMutation.action === "sign_confirmed" && (
                            <FormSuccess title="Wniosek został przesłany" text="Dziękujemy za przesłanie wniosku. Nasz zespół zajmie się nim tak szybko, jak będzie to możliwe."/>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {!isSuccess && (
                            <Flex flexDir={["column-reverse", "column-reverse", "row"]} justifyContent="center" w="100%">
                                <Button backgroundColor="lightPrimary" _hover={{backgroundColor: "lighterPrimary"}} color="white" p="10px 30px" size="sm" h="35px"
                                        onClick={() => onSubmit({...inputs})} isLoading={isLoading} isDisabled={
                                    (inputs.reason === "" && inputs.body.trim().length < 10) ||
                                    inputs.expiredate === ""
                                }>
                                    Wyślij
                                </Button>
                            </Flex>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
