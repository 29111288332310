import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Card, Detail } from "../..";
import { useUserBalance } from "../../../../hooks/useUserBalance";
import { useUserData } from "../../../../hooks/useUserData";
import { createBankAccountNumber } from "../../../utils";

export const FinanceSettlementsCard: FC = () => {
  const userBalance = useUserBalance().data;
  const userData = useUserData().data;

  return (
    <Card title="Rozliczenia">
      <Detail title="NUMER KONTA DO WPŁAT" textSize="24px" color="black">
        {createBankAccountNumber(userData?.bankaccount)}
      </Detail>
      <Flex justifyContent="space-between" flexWrap={"wrap"}>
        <Detail
          title="Twoje saldo"
          textSize="39px"
          color={userBalance > 0 ? "lightPrimary" : "darkRed"}
          margin="0 10px 0 0"
        >
          {userBalance ? userBalance + " zł" : "Brak danych"}
        </Detail>
      </Flex>
    </Card>
  );
};
