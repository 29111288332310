import {FC, useState} from "react";
import {Table, Thead, Tbody, Tr, Th, Td, Text, Button} from "@chakra-ui/react";
import "./index.css";
import {ESim} from "../../ESim";
import {CustomedButton} from "../../CustomedButton";

export interface IPhone {
    lpa?: string;
    phone: string;
}

export interface IPhoneNumbersTable {
    phones: IPhone[];
}

export const PhoneNumbersTable: FC<IPhoneNumbersTable> = ({phones}) => {

    const [qrCodes, setQrCodes] = useState<number[]>([]),
        showQrCode = (selectedPhoneIndex: number) => {
            setQrCodes((qrCodesArray: number[]) => {
                const qrIndex = qrCodesArray.indexOf(selectedPhoneIndex);
                if (qrIndex == -1) {
                    console.log('not found', [...qrCodesArray, selectedPhoneIndex]);
                    return [...qrCodesArray, selectedPhoneIndex];
                } else {
                    const result = [...qrCodesArray];
                    result.splice(qrIndex, 1);
                    console.log('found', result, qrCodes);
                    return result;
                }
            });
            // phones[selectedPhoneIndex].isQrCodeVisible = true;
            // console.log(phones[selectedPhoneIndex]);
        };

    return (
        <Table variant="simple">
            <Thead>
                <Tr>
                    <Th>Numer telefonu</Th>
                    <Th></Th>
                </Tr>
            </Thead>

            <Tbody>
                {phones.length ? (
                    phones.map(({phone, lpa}, index) => (
                        <Tr key={phone}>
                            <Td>
                                <Text class={'phoneNumber'}>
                                    {phone}
                                </Text>
                            </Td>
                            {lpa ? (
                                <ESim
                                    qrCode={lpa}
                                    button={
                                        <CustomedButton
                                            label="E-SIM"
                                        />
                                    }
                                />
                            ) : ''}
                        </Tr>
                    ))
                ) : (
                    <Tr>
                        <Td>Brak danych</Td>
                    </Tr>
                )}
            </Tbody>
        </Table>
    );
}
