import {Box, Container, Fade, Flex} from "@chakra-ui/react";
import {FC} from "react";

import bannerImage from "../../../src/img/bannerHosting/hosting_top.jpg";
import {useUserData} from "../../hooks/useUserData";
import {
    Banner,
    Card,
    ContactFormModal,
    CustomedButton,
    PageHeader,
} from "../../shared/components";
import {BannerClass} from "../../shared/components/Banner/utils";
import {useFirm} from "../../hooks/useFirm";
import { env } from "../../env";

export const SpeedTest: FC = () => {
    const userData = useUserData(),
        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno),
        {speedtest_url} = useFirm().data;

    return (
        <>
            <Banner extraContent={
                <ContactFormModal
                    button={
                        <CustomedButton
                            centered={true}
                            fontSize="22px"
                            height="50px"
                            label="Przedłuż umowę"
                        />
                    }
                    action="contract"
                />
            } bannerImage={bannerImage} {...bannerDetails}/>
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title="Przetestuj internet" buttonText="Strona główna"/>
                    <Box>
                        <Card height={["600px", "600px", "450px", "550px"]}>
                            <Flex justify="center" width="100%" height="100%" maxW={["1200px", "60%", "800px", "1150px"]} margin="auto">
                                <iframe title="speedtest" scrolling="no" src={speedtest_url} height="100%" width="100%"></iframe>
                            </Flex>
                        </Card>
                    </Box>
                </Container>
            </Fade>
        </>
    );
};
