import { FC, Fragment } from "react";
import {
  Table,
  Tr,
  Th,
  Td,
  IconButton,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { Card } from "../../Card";
import {
  CustomedTableHead,
  MobileCheckTag,
  MobileCloseTag,
  MobileTableBody,
  StatusBadge,
} from "../..";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useContractsListWithAnnexes } from "../../../../hooks/useContractsListWithAnnexes";
import { IAgreement, IBEAgreements } from "../../../../core/types/Finance";
import {capitalizeFirstLetter, restructureDate} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { CustomedTableBody } from "../../CustomedTableBody";

export const restructureAgreements = (agreements: IBEAgreements) => {
  return agreements
    ? agreements.list.map(
        ({ id, active,blocked, signdate, annexes, contract_number, contractdate, status_desc }) => ({
          id,
          name: contract_number,
          blocked: blocked,
          date: restructureDate(contractdate),
          active: active,
          status: !!Number(active),
          status_desc: status_desc,
          annexes,
        })
      )
    : [];
};

export const AgreementsCard: FC = () => {
  const navigate = useNavigate();
  const rawAgreements = useContractsListWithAnnexes().data;
  const agreements: IAgreement[] = restructureAgreements(rawAgreements);

  return (
    <Card
      title="Umowy i aneksy"
      display="Flex"
      extraContent={
        <Flex justifyContent="flex-end">
          <Flex>
            {/* <Select
              placeholder="Status: Wszystkie"
              color="darkPrimary"
              borderColor="darkPrimary"
              minW="170px"
              mr="15px"
            >
              <option value="active">Aktywne</option>
              <option value="inactive">Niepodpisane</option>
            </Select>
            <Select
              placeholder="Rok: Wszystkie"
              color="darkPrimary"
              borderColor="darkPrimary"
            >
              <option value="2021">2021</option>
              <option value="2020">2020</option>
            </Select> */}
          </Flex>
        </Flex>
      }
    >
      <Table variant="simple">
        <CustomedTableHead>
          <Tr>
            <Th>Dokument</Th>
            <Th>Typ</Th>
            <Th>Data wystawienia</Th>
            <Th>Status</Th>
            <Th> {/* przycisk */} </Th>
          </Tr>
        </CustomedTableHead>

        <CustomedTableBody>
          {agreements.length ? (
            agreements.map(({ id, name, date, active, blocked, status, status_desc, annexes }) => (
              <Fragment key={id}>
                <Tr>
                  <Td fontWeight={600}>Umowa nr {name}</Td>
                  <Td
                    color="lightPrimary"
                    textTransform="uppercase"
                    fontWeight={600}
                  >
                    Umowa
                  </Td>
                  <Td fontWeight={600}>{date}</Td>
                  <Td>
                    {active === "1" && blocked === "0" ? (
                      <StatusBadge width="100%" label={capitalizeFirstLetter(status_desc || "Aktywna")} status="active" />
                    ) : (
                        <StatusBadge width="100%" label={capitalizeFirstLetter(status_desc || "Niepodpisana")} status="inactive" />
                    )}
                  </Td>
                  <Td>
                    <IconButton
                      variant="outline"
                      size="md"
                      aria-label="Show Details"
                      color="lightPrimary"
                      borderColor="lightPrimary"
                      icon={<ChevronRightIcon />}
                      onClick={() => {
                        navigate(`contract/${id}`);
                      }}
                    />
                  </Td>
                </Tr>
                {annexes.map(({ annexdate, annexno, id, signdate, contract_number }) => (
                  <Tr key={id}>
                    <Td fontWeight={600}>
                        <Text style={{paddingLeft:'20px'}}>
                            Aneks nr {annexno} do Umowy nr {contract_number}
                        </Text>
                    </Td>
                    <Td
                      color="lightPrimary"
                      textTransform="uppercase"
                      fontWeight={600}
                    >
                      Aneks
                    </Td>
                    <Td fontWeight={600}>{restructureDate(annexdate)}</Td>
                    <Td>
                        {blocked === "0" && (
                            (signdate === "0" ? false : !!signdate) ? (
                                <StatusBadge width="100%" label="Aktywna" status="active" />
                            ) : (
                                <StatusBadge width="100%" label="Niepodpisana" status="inactive" />
                            )
                        )}
                    </Td>
                    <Td>
                      <IconButton
                        variant="outline"
                        size="md"
                        aria-label="Show Details"
                        color="lightPrimary"
                        borderColor="lightPrimary"
                        icon={<ChevronRightIcon />}
                        onClick={() => {
                          navigate(`annex/${id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Fragment>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>Brak danych</Td>
            </Tr>
          )}
        </CustomedTableBody>
        <MobileTableBody>
          {agreements.length ? (
            agreements.map(({ id, name, date, status, annexes }) => (
              <Fragment key={"mobile-" + id}>
                <Tr key={name + date + "mobile"}>
                  <Td p="5px 0">
                    <Flex justifyContent={"space-between"} alignItems="center">
                      {status ? <MobileCheckTag /> : <MobileCloseTag />}
                      <Box m="0px auto 0px 10px">
                        <Text fontSize="14px" fontWeight={600}>
                          Umowa nr {name}
                        </Text>
                        <Text fontSize="14px">{date}</Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <Text
                      color="lightPrimary"
                      textTransform="uppercase"
                      fontWeight={600}
                    >
                      Umowa
                    </Text>
                  </Td>
                  <Td p="0px">
                    <Flex justifyContent="flex-end">
                      <ChevronRightIcon
                        color="darkPrimary"
                        fontSize="30px"
                        fontWeight="600"
                        onClick={() => {
                          navigate(`contract/${id}`);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
                {annexes.map(({ annexdate, annexno, id, signdate }) => (
                  <Tr key={id + annexno + "mobile"}>
                    <Td p="5px 0">
                      <Flex justifyContent="space-between" alignItems="center">
                        {status ? <MobileCheckTag /> : <MobileCloseTag />}
                        <Box m="0px auto 0px 10px">
                          <Text fontSize="14px" fontWeight={600}>
                            Aneks nr {annexno}
                          </Text>
                          <Text fontSize="14px">
                            {restructureDate(annexdate)}
                          </Text>
                        </Box>
                      </Flex>
                    </Td>
                    <Td>
                      <Text
                        color="lightPrimary"
                        textTransform="uppercase"
                        fontWeight={600}
                      >
                        Aneks
                      </Text>
                    </Td>
                    <Td p="0px">
                      <Flex justifyContent="flex-end">
                        <ChevronRightIcon
                          color="darkPrimary"
                          fontSize="30px"
                          fontWeight="600"
                          onClick={() => {
                            navigate(`annex/${id}`);
                          }}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Fragment>
            ))
          ) : (
            <Tr>
              <Td>Brak danych</Td>
            </Tr>
          )}
        </MobileTableBody>
      </Table>
    </Card>
  );
};
