import { Flex, FlexProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { CustomBox, CustomHeader } from "./chakra";

export interface ICard {
  title?: string;
  titleStyle?: object;
  variant?: "light" | "dark";
  display?: string | string[];
  displayHeaderOnMobile?: boolean;
  extraContent?: ReactNode;
  fullHeight?: boolean;
}

export const Card: FC<ICard & FlexProps> = ({
  title,
  titleStyle,
  variant,
  display,
  displayHeaderOnMobile = true,
  fullHeight = true,
  extraContent,
  children,
  ...rest
}) => (
  <Flex
    flexDir="column"
    w="full"
    h={fullHeight ? "full" : "auto"}
    display={display}
    {...rest}
  >
    <Flex
      w="full"
      justify="space-between"
      align="center"
      mb={2}
      display={displayHeaderOnMobile ? ["flex"] : ["none", "none", "flex"]}
    >
      {title && <CustomHeader m={0} style={titleStyle ? titleStyle : {}}>{title}</CustomHeader>} {extraContent}
    </Flex>
    <CustomBox
      position="relative"
      backgroundColor={variant === "dark" ? "darkerPrimary" : "white"}
    >
      {children}
    </CustomBox>
  </Flex>
);
