import {Container, Fade} from "@chakra-ui/react";
import {FC} from "react";
import {useInternetList} from "../../hooks/useInternetList";
// import {useInternetNode} from "../../hooks/useInternetNode";
import {Banner, Card, PageHeader} from "../../shared/components";
import {BannerClass} from "../../shared/components/Banner/utils";
import {InternetTable} from "../../shared/components/Tables";

import bannerImage from "../../../src/img/bannerHosting/hosting_top.jpg";
import {useUserData} from "../../hooks/useUserData";
import { env } from "../../env";

export const Internet: FC = () => {
    const {data} = useInternetList(),
        userData = useUserData(),

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno);

    return (
        <>
            <Banner bannerImage={bannerImage} {...bannerDetails} />
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title="Internet" onlyMobile/>
                    <Card title="Twoje adresy IP">
                        <InternetTable connections={data}/>
                    </Card>
                </Container>
            </Fade>
        </>
    );
};
