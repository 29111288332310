import { ButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import { CustomButton } from "./chakra";

export interface ICustomedButton {
  label: string;
  onClick?: () => void;
  centered?: boolean;
  disabled?: boolean;
  height?: string;
  margin?: string;
  size?: string;
}

export const CustomedButton: FC<ICustomedButton & ButtonProps> = ({
  label,
  centered,
  disabled = false,
  height,
  margin,
  size,
  leftIcon,
  rightIcon,
  onClick,
  ...rest
}) => (
  <CustomButton
    // colorScheme={color ? color : "blue"}
    height={height}
    size={size}
    onClick={onClick}
    disabled={disabled}
    alignSelf={centered ? "center" : "normal"}
    margin={margin}
    _hover={{ backgroundColor: "lighterPrimary" }}
    zIndex={2}
    {...rest}
  >
    {label}
  </CustomButton>
);
