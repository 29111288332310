import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getInvoicesLast = async () => getJSON(endpoints.invoices.last);

export const useInvoicesLast = () => {
  const { data, isLoading, error } = useQuery("invoicesLast", getInvoicesLast, {
    staleTime: Infinity,
  });

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
