import { FC, useState } from "react";
import { Container, Divider, Flex, Text, IconButton } from "@chakra-ui/react";
import { BellIcon, HamburgerIcon, PhoneIcon } from "@chakra-ui/icons";

import { CustomLogo, TopBarWrapper } from "./chakra";
import { MobileNavigation, SubNavigation } from "..";

import logoImg from "../../../img/logo/logo.svg";
import { UserMenu } from "../UserMenu";
import { useUserData } from "../../../hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { useFirm } from "../../../hooks/useFirm";

export const Header: FC = () => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState<"none" | "flex">(
      "none"
  );

  const navigate = useNavigate();
  const user = useUserData();
  const firm = useFirm();

  const { url_eboklogo, phone } = firm.data;

  return (
      <Flex
          width={"full"}
          pt="15px"
          pb={["15px", "15px", "0px"]}
          mb={["15px", "15px", "0px"]}
          backgroundColor="white"
      >
        <Container maxWidth={"container.xl"} flexDir={"column"}>
          <TopBarWrapper>
            <CustomLogo
                src={url_eboklogo || logoImg}
                onClick={() => {
                  navigate("/");
                }}
            />
            <Flex
                alignItems={"center"}
                display={["none", "none", "flex", "flex"]}
            >
              <PhoneIcon color="gray" fontSize="24px" transform="scale(-1,1)" />
              <Text
                  color="lightPrimary"
                  fontWeight="600"
                  fontSize="21px"
                  mx="15px"
              >
                {phone?.split(",")[0]}
              </Text>
              <BellIcon color="gray" fontSize="24px" />
              <Divider
                  orientation="vertical"
                  w="1px"
                  h="30px"
                  bg="gray60"
                  mx="10px"
              />

              {/* User menu */}
              <UserMenu
                  name={user.data?.fullname}
                  imageUrl="/user.png"
              />
            </Flex>
            <IconButton
                aria-label="Open menu"
                size="lg"
                mr={2}
                icon={<HamburgerIcon />}
                display={["flex", "flex", "none", "none"]}
                onClick={() => {
                  setDisplayMobileMenu("flex");
                }}
            />
          </TopBarWrapper>
          <SubNavigation />
        </Container>

        {/* Mobile Navigation */}
        <MobileNavigation
            display={displayMobileMenu}
            setDisplay={setDisplayMobileMenu}
        />
      </Flex>
  );
};
