import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useRodo } from "../../../../hooks/useRodo";
import { isTypeOnlyImportOrExportDeclaration } from "typescript";
import { useIsComponentMounted } from "../../../../hooks/useIsMounted";

export interface IRodoModal {
    onCloseModal?: Function;
}

export const RodoModal: FC<IRodoModal> = ({ onCloseModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure(),
        onFormClose = () => {
            if (data && !data.force_accept) {
                onClose();
            }
            if (onCloseModal) {
                onCloseModal()
            }
        },
        [accepted, setAccepted] = useState<boolean>(false),
        isMounted = useIsComponentMounted(),
        { data, acceptRodo } = useRodo(onClose),
        onSubmit = () => {
            acceptRodo();
        };

    useEffect(() => {
        if (!isOpen) {
            onOpen();
        }
    }, [data]);

    return (
        <Modal isOpen={isOpen} onClose={onFormClose}>
            <ModalOverlay onClick={onFormClose} />
            <ModalContent minW={["100%", "100%", "500px"]}
                minH={["100%", "100%", "initial"]}
                m={["0px", "0px", "60px 0px"]}
                borderRadius={["0px", "0px", "10px"]}>
                <ModalHeader color="darkPrimary">Klauzula RODO</ModalHeader>
                {data && !data.force_accept && <ModalCloseButton fontSize={["20px", "20px", "12px"]}
                    m={["10px", "10px", "0px"]} />
                }
                <ModalBody>
                    {data &&
                        <div dangerouslySetInnerHTML={{ __html: data.html }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="center"
                        w="100%"
                        flexDir={["column-reverse", "column-reverse", "row"]}>
                        <Button backgroundColor="lightPrimary"
                            _hover={{ backgroundColor: "lighterPrimary" }}
                            color="white"
                            p="10px 30px"
                            size="sm"
                            h="35px"
                            onClick={() =>{
                                onFormClose()
                                onSubmit()
                            }}>
                            Akceptuję
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
