import { FC } from "react";
import { Table, Tr, Th, Td, Text, IconButton, Flex } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";

export interface IPackage {
  name: string;
  deviceCount: string;
  packetCount: string;
  amount: string;
  id?: string;
}

export interface ITelevisionTable {
  packages: IPackage[];
}

export const TelevisionTable: FC<ITelevisionTable> = ({ packages }) => {
  const navigate = useNavigate();

  return (
    <Table variant="simple">
      <CustomedTableHead>
        <Tr>
          <Th>Taryfa</Th>
          <Th>Ilość urządzeń</Th>
          <Th>Ilość pakietów</Th>
          <Th>Kwota brutto</Th>
          <Th>{/*Przycisk*/}</Th>
        </Tr>
      </CustomedTableHead>

      <CustomedTableBody>
        {packages.length ? (
          packages.map(({ id, name, deviceCount, packetCount, amount }) => (
            <Tr key={name}>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {name}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="lightPrimary" fontWeight="600">
                  {deviceCount}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="lightPrimary" fontWeight="600">
                  {packetCount}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary">
                  {amount} zł
                </Text>
              </Td>
              <Td>
                <IconButton
                  variant="outline"
                  size="md"
                  aria-label="Show Details"
                  color="lightPrimary"
                  borderColor="lightPrimary"
                  icon={<ChevronRightIcon />}
                  onClick={() => {
                    navigate(`package/${id}`);
                  }}
                />
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </CustomedTableBody>

      <MobileTableBody>
        {packages.length ? (
          packages.map(({ id, name, amount }) => (
            <Tr key={id + "mobile"}>
              <Td>
                <Flex justifyContent={"space-between"}>
                  <Text fontSize="17px" fontWeight={600}>
                    {name}
                  </Text>
                  <Text fontSize="17px" fontWeight={600}>
                    {amount} zł
                  </Text>
                </Flex>
              </Td>
              <Td p="0px">
                <Flex justifyContent="flex-end">
                  <ChevronRightIcon
                    color="darkPrimary"
                    fontSize="30px"
                    fontWeight="600"
                    onClick={() => {
                      navigate(`package/${id}`);
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
          </Tr>
        )}
      </MobileTableBody>
    </Table>
  );
};
