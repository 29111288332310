export const fontSize = {
  smallerSize: "12px",
  smallSize: "14px",
  mediumSize: "16px",
  largeSize: "24px",
  mobileMedium: "18px",
  mobileLarge: "19px",
  mobileLargest: "22px",
  mobileBiggest: "32px",
  menuNumber: "21px",
  contactBoxSize: "23px",
  iconSize: "28px",
  speedSize: "17px",
};
