import {
    formatPhoneNumber,
    formatTime,
    restructureDate,
    formatTransfer,
} from "./../shared/utils";
import {useQuery} from "react-query";
import {getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {IQueryOptions} from "../core/types";
import {stringify, ParsedUrlQueryInput} from "querystring";

export interface IBEBillingRecord {
    id: string;
    calldate: string;
    userid: string;
    mobiles_id: string;
    ano: string;
    src: string;
    dst: string;
    traffic_categories_id: string;
    traffic_categories_name: string;
    duration: string;
    datatransfer: string;
    msg: string;
    totalnetto: string;
    realtotalnetto: string;
    realtotalbrutto: string;
}

export interface IBEMobileBilling {
    list: IBEBillingRecord[];
    total: number;
}

export const restructureBillingData = (telephony: IBEMobileBilling) =>
    telephony && telephony.list
        ? telephony.list.map(
            ({
                 ano,
                 calldate,
                 dst,
                 duration,
                 realtotalbrutto,
                 traffic_categories_name,
                 datatransfer,
                 id,
             }) => ({
                date: restructureDate(calldate?.split(" ")[0]),
                account: formatPhoneNumber(ano),
                calledNumber: formatPhoneNumber(dst),
                category: traffic_categories_name,
                callTime: formatTime(Number(duration)),
                transfer: formatTransfer(Number(datatransfer)),
                amount:
                    realtotalbrutto.split(".")[0] +
                    "." +
                    (realtotalbrutto.split(".")[1]
                        ? realtotalbrutto.split(".")[1].substring(0, 2)
                        : "00") +
                    " zł",
                id: id,
            })
        )
        : [];

const getMobileBillingList = async (stringParams: string) =>
    getJSON(
        [endpoints.mobile.billing, stringParams && "?", stringParams].join("")
    );

export const useMobileBillingList = ({
                                         pagination,
                                         filters,
                                     }: IQueryOptions) => {
    let urlParams = "";

    if (pagination)
        urlParams = stringify(pagination as unknown as ParsedUrlQueryInput);

    if (filters && pagination) urlParams += "&";
    if (filters) urlParams += stringify(filters);

    const {data, isLoading, error} = useQuery(
        "MobileBillingList" + urlParams,
        () => getMobileBillingList(urlParams),
        {
            staleTime: Infinity,
        }
    );

    return {
        isLoading,
        data: { list: restructureBillingData(data?.data), total: data?.data.total },
        error,
    };
};
