import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { stringify } from "querystring";
import { IQueryOptions } from "../core/types";
import { env } from "../env"

export interface IBEInternetNode {
  ipaddr_string: string;
  pppoe_login: string;
  pppoe_password: string;
  proxyaddress: string;
  upceil: string;
  downceil: string;
  uprate: string;
  downrate: string;
  nightupceil: string;
  nightdownceil: string;
  nightuprate: string;
  nightdownrate: string;
  NetworkData: { mask: string; gateway: string };
  ServerData: { dns1: string; dns2: string };
  ServiceData: { tariffname: string; location_address: string; active: string };
}
const kilobitToMegabitDivisor = (Number(env.REACT_APP_KILOBIT_TO_MEGABIT_DIVISOR) === 1024) ? 1024 : 1000
// const kilobitToMegabitDivisor = 1000

export const convertFromKilobitToMegabit = (value: string) => Math.round(Number(value) / kilobitToMegabitDivisor);
export const restructureInternetNode = (internetNode: IBEInternetNode) => {
  if (internetNode) {
    const {
      ipaddr_string,
      pppoe_login,
      pppoe_password,
      proxyaddress,
      upceil,
      downceil,
      uprate,
      downrate,
      nightupceil,
      nightdownceil,
      nightuprate,
      nightdownrate,
      NetworkData,
      ServerData,
      ServiceData,
    } = internetNode;

    return {
      ip: ipaddr_string,
      config: {
        mask: NetworkData.mask,
        gateway: NetworkData.gateway,
        dns1: ServerData.dns1,
        dns2: ServerData.dns2,
        pppoe_login,
        pppoe_password,
      },
      service: {
        name: ServiceData.tariffname,
        location: ServiceData.location_address,
        proxy: proxyaddress,
        status: ServiceData.active,
      },
      speed: {
        day: {
          max: {
            upload: convertFromKilobitToMegabit(upceil),
            download: convertFromKilobitToMegabit(downceil),
          },
          guaranteed: {
            upload: convertFromKilobitToMegabit(uprate),
            download: convertFromKilobitToMegabit(downrate),
          },
        },
        night: {
          max: {
            upload: convertFromKilobitToMegabit(nightupceil),
            download: convertFromKilobitToMegabit(nightdownceil),
          },
          guaranteed: {
            upload: convertFromKilobitToMegabit(nightuprate),
            download: convertFromKilobitToMegabit(nightdownrate),
          },
        },
      },
    };
  }

  return { config: {}, service: {}, speed: {} };
};

const getInternetNode = async (id: string, stringParams: string) =>
  getJSON(
    [endpoints.internet.details, id, stringParams && "?", stringParams].join("")
  );

export const useInternetNode = ({ id, filters }: IQueryOptions) => {
  let urlParams = "";

  if (filters) urlParams += stringify(filters);

  const { data, isLoading, error } = useQuery(
    "InternetNode" + id + urlParams,
    () => getInternetNode(id!, urlParams),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureInternetNode(data?.data),
    error,
  };
};
