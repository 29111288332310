import {Button, Flex, Image, Text} from "@chakra-ui/react";
import {FC} from "react";
import {FaAngleRight} from "react-icons/fa";
import {Card} from "../../Card";

import contactImage from "../../../../img/contact/iconHeadset.png";
import {ContactFormModal} from "../..";
import {useFirm} from "../../../../hooks/useFirm";

export const ContactCard: FC = () => {
    const {phone} = useFirm().data;
    return (
        <Card variant="dark" fullHeight={false}>
            <Text fontSize="14px" lineHeight="20px" mb="15px" color="white">
                KONTAKT
            </Text>
            <Flex flexDir="row" flexWrap="wrap" justify="space-between">
                <Image
                    src={contactImage}
                    mr="10px"
                    display={["none", "none", "none", "none", "initial"]}
                />
                <Flex flexDir="column">
                    <Text fontSize="23px" color="lightPrimary">
                        {phone?.split(",")[0]}
                    </Text>
                    <ContactFormModal
                        button={
                            <Button size="sm" rightIcon={<FaAngleRight/>}>
                                Skontaktuj się z nami
                            </Button>
                        }
                    />
                </Flex>
            </Flex>
        </Card>
    )
};
