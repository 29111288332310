import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { ParsedUrlQueryInput, stringify } from "querystring";
import { IQueryOptions } from "../core/types";
import {
  formatPhoneNumber,
  restructureDate,
  secondsToMinutes,
} from "../shared/utils";

export interface IBEBillingRecord {
  id: string;
  calldate: string;
  userid: string;
  phones_id: string;
  accountcode: string;
  src: string;
  dst: string;
  voip_categories_id: string;
  voip_categories_name: string;
  dcontext: string;
  dcontext_desc: string;
  hangupcause: string;
  disposition: string;
  disposition_desc: string;
  billsec: string;
  totalnetto: string;
  realtotalnetto: string;
  realtotalbrutto: string;
}

export interface IBEBilling {
  list: IBEBillingRecord[];
  total: number;
}

export const restructureBillingData = (telephony: IBEBilling) =>
  telephony
    ? telephony.list.map(
        ({
          calldate,
          dst,
          billsec,
          dcontext_desc,
          realtotalbrutto,
          voip_categories_name,
          accountcode,
          id,
        }) => ({
          date: restructureDate(calldate.split(" ")[0]),
          account: formatPhoneNumber(accountcode),
          calledNumber: formatPhoneNumber(dst),
          category: voip_categories_name || "Brak",
          type: dcontext_desc,
          callTime: secondsToMinutes(Number(billsec)),
          amount:
            realtotalbrutto.split(".")[0] +
            "." +
            (realtotalbrutto.split(".")[1]
              ? realtotalbrutto.split(".")[1].substring(0, 2)
              : "00") +
            " zł",
          id: id,
        })
      )
    : [];

const getBillingList = async (stringParams: string) =>
  getJSON(
    [endpoints.telephony.billing, stringParams && "?", stringParams].join("")
  );

export const useBillingList = ({ pagination, filters }: IQueryOptions) => {
  let urlParams = "";

  if (pagination)
    urlParams = stringify(pagination as unknown as ParsedUrlQueryInput);

  if (filters && pagination) urlParams += "&";
  if (filters) urlParams += stringify(filters);

  const { data, isLoading, error } = useQuery(
    "BillingList" + urlParams,
    () => getBillingList(urlParams),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: { list: restructureBillingData(data?.data), total: data?.data.total },
    error,
  };
};
