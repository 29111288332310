import {useQuery} from "react-query";
import {getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {formatPhoneNumber} from "../shared/utils";

interface IBEPhone {
    ano: string;
    context: string;
    creationdate: string;
    id: string;
    userid: string;
    sim_lpa: string;
}

interface IBEPhones {
    list: IBEPhone[];
    total: string;
}

const getMobileBillingPhones = async () => getJSON(endpoints.mobile.phones);

const restructurePhonesData = (data: IBEPhones) =>
    data && data.list
        ? data.list.map(({ano, sim_lpa}) => ({
            accountcode: ano,
            phone: formatPhoneNumber(ano),
            lpa: sim_lpa
        }))
        : [];

export const useMobileBillingPhones = () => {
    const {data, isLoading, error} = useQuery(
        "MobileBillingPhones",
        getMobileBillingPhones,
        {
            staleTime: Infinity,
        }
    );

    return {
        isLoading,
        data: restructurePhonesData(data?.data),
        error,
    };
};
