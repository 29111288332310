import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getBillingContextDict = async () =>
  getJSON(endpoints.telephony.dict.context);

export const useBillingContextDict = () => {
  const { data, isLoading, error } = useQuery(
    "BillingContextDict",
    getBillingContextDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const getBillingDispositionDict = async () =>
  getJSON(endpoints.telephony.dict.disposition);

export const useBillingDispositionDict = () => {
  const { data, isLoading, error } = useQuery(
    "BillingDispositionDict",
    getBillingDispositionDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const getBillingCountryDict = async () =>
  getJSON(endpoints.telephony.dict.country);

export const useBillingCountryDict = () => {
  const { data, isLoading, error } = useQuery(
    "BillingCountryDict",
    getBillingCountryDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const getBillingMonthDict = async () => getJSON(endpoints.telephony.dict.month);

export const useBillingMonthDict = () => {
  const { data, isLoading, error } = useQuery(
    "BillingMonthDict",
    getBillingMonthDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
