import {
    FaWifi,
    FaPhoneVolume,
    FaCreditCard,
    FaTv,
    FaIdCard,
    FaLock,
    FaFileImage,
    FaQuestionCircle,
    FaSignOutAlt,
    FaMobileAlt,
} from "react-icons/fa";
import {Divider} from "@chakra-ui/react";

export interface NavItem {
    id?: string;
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
    icon?: JSX.Element;
    divide?: JSX.Element;
}

export const navItems: Array<NavItem> = [
    {
        id: "1",
        label: "Finanse",
        icon: <FaCreditCard/>,
        href: "/payment",
    },
    {
        id: "2",
        label: "Internet",
        icon: <FaWifi/>,
        href: "/internet",
    },
    {
        id: "3",
        label: "Telefonia",
        icon: <FaPhoneVolume/>,
        href: "/telephony",
    },
    {
        id: "4",
        label: "Tel. mobilna",
        icon: <FaMobileAlt/>,
        href: "/mobile",
    },
    // {
    //   label: "Hosting & email",
    //   icon: <FaGlobe />,
    //   href: "/hosting",
    // },
    {
        id: "5",
        label: "Telewizja",
        icon: <FaTv/>,
        href: "/television",
    },
];

export const userMenuItems: Array<NavItem> = [
    {
        label: "Edytuj profil",
        icon: <FaIdCard/>,
    },
    {
        label: "Zmień hasło",
        icon: <FaLock/>,
    },
    {
        label: "Twoje zgody",
        icon: <FaFileImage/>,
    },
    {
        label: "Formularz kontaktowy",
        icon: <FaQuestionCircle/>,
    },
    {
        label: "Wyloguj",
        divide: <Divider style={{marginBottom: "19.5px", marginTop: "19.5px"}}/>,
        icon: <FaSignOutAlt style={{color: "#262F47"}}/>,
    },
];
