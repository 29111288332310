import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getUserData = async () => getJSON(endpoints.user.data);

export const useUserData = () => {
  const { data, refetch, isLoading, error } = useQuery(
    "userData",
    getUserData,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
    refetch,
  };
};
