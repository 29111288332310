import { TableBodyProps, Tbody } from "@chakra-ui/react";
import { FC } from "react";

const noMobileDisplay = ["none", "none"];

export const CustomedTableBody: FC<TableBodyProps> = ({
  children,
  ...rest
}) => (
  <Tbody {...rest} display={[...noMobileDisplay, "table-row-group"]}>
    {children}
  </Tbody>
);
