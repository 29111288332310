import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { IBEPayments, IQueryOptions } from "../core/types";
import { restructureDate } from "../shared/utils";
import { ParsedUrlQueryInput, stringify } from "querystring";

const getPaymentsList = async (stringParams: string) =>
  getJSON(
    [endpoints.payments.list, stringParams && "?", stringParams].join("")
  );

export const restructurePayments = (payments: IBEPayments) =>
  payments
    ? payments.list.map(
        ({ id, BZp_DataDok_UTS, BZp_Kwota, BZp_NumerPelny }) => ({
          id,
          name: BZp_NumerPelny,
          date: restructureDate(BZp_DataDok_UTS),
          amount: BZp_Kwota,
        })
      )
    : [];

export const usePaymentsList = ({ pagination }: IQueryOptions) => {
  let urlParams = "";

  if (pagination)
    urlParams = stringify(pagination as unknown as ParsedUrlQueryInput);

  const { data, isLoading, error } = useQuery(
    "paymentsList" + urlParams,
    () => getPaymentsList(urlParams),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: { list: restructurePayments(data?.data), total: data?.data.total },
    error,
  };
};
