import { useMutation } from "react-query";
import { extendedPostJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

interface IAnnexSms {
  pin?: string;
}

const postAnnexSms = (id: string, data: any) =>
  extendedPostJSON(endpoints.annexes.details + `/${id}/sign`, data);

export const useAnnexSms = (id: string) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [action, setAction] = useState("");

  const { mutate, isLoading, isSuccess, isError, reset } = useMutation(
    (data: IAnnexSms) => postAnnexSms(id, data),
    {
      onSuccess: ({ data }) => {
        if (data === true) {
          setAction("sign_confirmed");
        } else if (data > 0) {
          setAction("sms_send");
        }
      },
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => {},
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    errors,
    action,
    annexSms: mutate,
    resetForm: reset,
  };
};
