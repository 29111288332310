import { Box, Container, Fade, Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import { FC, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { BannerClass } from '../../shared/components/Banner/utils';
import {
    Banner,
    ChangePasswordModal,
    ContactCard,
    ContactFormModal,
    CustomedButton,
    CustomedSpinner,
    LastPaymentCard,
    MyAccountCard,
    RegulationModal,
    RodoModal,
    ServicesCard,
    SettlementsCard,
    SpeedTestCard
} from '../../shared/components';
import { LocalStorageService } from '../../services/LocalStorageService';
import { useUserData } from '../../hooks/useUserData';
import { useUserBalance } from '../../hooks/useUserBalance';
import { useInvoicesLast } from '../../hooks/useInvoicesLast';
import { createBankAccountNumber, restructureDate } from '../../shared/utils';
import { useFirm } from '../../hooks/useFirm';
import { useQueryClient } from 'react-query';
import { deleteJSON } from '../../api/http';
import { endpoints } from '../../api/endpoints';
import { LoginStateContext } from '../../shared/components/LoginState';
import { GlobalDamagesCard } from '../../shared/components/Cards/GlobalDamages';
import { useGlobalDamages } from '../../hooks/useGlobalDamages';
import { env } from '../../env';

interface IUserFlags {
    passwordChanged: boolean;
    rodoAccepted?: boolean;
    regulationAccepted?: boolean;
}

export const Dashboard: FC = () => {
    const { getToken, setToken } = LocalStorageService(),
        { ebok_rights, speedtest_url } = useFirm().data,
        { totalDamagesLength } = useGlobalDamages().data,
        userBalance = useUserBalance().data,
        user = useUserData(),
        lastInvoice = useInvoicesLast(),

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? user.data?.usercode : user.data?.userno),
        // bannerDetails = new BannerClass(user.data?.userno),

        [isPageLoading, setIsPageLoading] = useState(true),
        [userFlags, setUserFlags] = useState<IUserFlags>(
            {
                passwordChanged: false,
                rodoAccepted: false,
                regulationAccepted: false,
            }
        ),

        { dispatch } = useContext(LoginStateContext),
        queryClient = useQueryClient(),
        deleteLogout = async () => deleteJSON(endpoints.auth),
        onRodoAccepted = async () => {
            setUserFlags({ ...userFlags, rodoAccepted: true })
        },
        onRegulationAccepted = async () => {
            setUserFlags({ ...userFlags, regulationAccepted: true })
        },
        handleLogout = () => {
            queryClient.invalidateQueries();
            deleteLogout();
            dispatch({ type: 'NOT_LOGGED' });
        };

        // console.log('ENV USER: ' + env.REACT_APP_USE_USER_CODE)
        // console.log('ENV DIVISOR: ' + env.REACT_APP_KILOBIT_TO_MEGABIT_DIVISOR)
        // console.log('process ENV USER: ' + process.env.REACT_APP_USE_USER_CODE)
        // console.log('process ENV DIVISOR: ' + process.env.REACT_APP_KILOBIT_TO_MEGABIT_DIVISOR)
        

    // const documentRef = useRef(document);
    // const rodoAccepted = useCallback((e) => {setUserFlags({ ...userFlags, regulationAccepted: e.detail })}, [])    
    // const rodoAccepted = useCallback((e) => {console.log('udalo sie zrobic event, data: ' + JSON.stringify(e.detail))}, [])    

    // useEffect(() => {

    //     documentRef.current.addEventListener('rodoAccepted', rodoAccepted)

    //     return () => {
    //         documentRef.current.removeEventListener('rodoAccepted', rodoAccepted)
    //     }

    // }, [])

    useEffect(() => {

        // console.log('userData:  ' + JSON.stringify(user.data))
        // console.log('regulation token:  ' + getToken('regulation'))
        // console.log('rodo token:  ' + getToken('rodo'))
        if (user.data)
            setUserFlags(
                {
                    passwordChanged: user.data.ebok_password_changed !== '0' ? true : false,
                    rodoAccepted: user.data.accepteddate_rodo !== '0' ? true : false,
                    regulationAccepted: user.data.accepteddate_regulations !== '0' ? true : false,
                }
            )

        if (!userBalance && !user.data && !lastInvoice.data) {
            setIsPageLoading(true);
        } else {
            setTimeout(() => {
                setIsPageLoading(false);
            }, 300);
        }
    }, [userBalance, user.data, lastInvoice.data]);

    // useEffect(() => {
    //     console.log('pass changed: ' + userFlags.passwordChanged)
    //     console.log('rodo accepted: ' + userFlags.rodoAccepted)
    //     console.log('regulation accepted: ' + userFlags.regulationAccepted)
    // }, [userFlags])

    if (isPageLoading) {
        return <CustomedSpinner />;
    }

    return (
        <Fade in={true}>
            <Banner
                extraContent={
                    <ContactFormModal button={
                        <CustomedButton centered={true} fontSize="22px" height="50px" label="Przedłuż umowę" />
                    } action="contract" />
                }
                {...bannerDetails}
            />

            {(!userFlags.passwordChanged && !getToken('changepassword')) ?
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <ChangePasswordModal button={undefined} canBeClosed={false} setChangedPass={handleLogout} description={"Aby przejść dalej musisz zmienić hasło. Po zmianie hasła zostaniesz wylogowany"}
                        noButton={true} />
                </Container> : null
            }

            {(!userFlags.rodoAccepted && userFlags.passwordChanged && !getToken('rodo')) ?
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <RodoModal onCloseModal={onRodoAccepted} />
                </Container> : null
            }

            {(!userFlags.regulationAccepted && userFlags.passwordChanged && userFlags.rodoAccepted && !getToken('regulation')) ?
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <RegulationModal
                        onCloseModal={onRegulationAccepted}
                    />
                </Container> : null
            }

            <Container maxWidth={"container.xl"} flexDir={"column"}>
                <Grid templateColumns={[
                    "repeat(3, 1fr)",
                    "repeat(3, 1fr)",
                    "repeat(9, 1fr)",
                    "repeat(8, 1fr)",
                ]} gap={30} mb="20px">
                    {/* Moje konto */}
                    <GridItem colSpan={[3, 3, 3, 2]} display={["initial"]}>
                        <MyAccountCard userNumber={env.REACT_APP_USE_USER_CODE === 'true' ? user.data?.usercode : user.data?.userno} />
                    </GridItem>

                    {/* Rozliczenia */}
                    <GridItem colSpan={3}>
                        {(ebok_rights?.includes('1') &&
                            <SettlementsCard paymentAccount={createBankAccountNumber(user.data?.bankaccount)} userBalance={userBalance ? userBalance + " zł" : "Brak danych"} />
                        )}
                    </GridItem>

                    {/* Usługi */}
                    <GridItem colSpan={3}>
                        <ServicesCard />
                    </GridItem>

                    {/* Wiadomości i kontakt */}
                    <GridItem colSpan={[3, 3, 3, 2]} /* h={'289px'} */ /* display={["initial"]} */>
                        <Flex flexDir="column" /* h={'100%'} */ justify={'space-between'}>
                            <ContactCard />
                            {
                                totalDamagesLength ?
                                    <Box display={["block", "block", "block"]} boxShadow={["md", "md", "md"]} borderLeftRadius="10px" maxH={'144.5px'} overflowY='auto' >
                                        <GlobalDamagesCard />
                                    </Box>
                                    : null
                            }
                        </Flex>
                    </GridItem>

                    {/* Ostatnia płatność */}
                    <GridItem colSpan={3}>
                        {(ebok_rights?.includes('1') &&
                            <LastPaymentCard documentNumber={lastInvoice.data?.TrN_NumerPelny || null} date={lastInvoice.data?.TrN_Termin_UTS} id={lastInvoice.data?.id}
                                status={lastInvoice.data?.settled} toPay={lastInvoice.data?.settled_left} />
                        )}
                    </GridItem>

                    {/* SpeedTest */}
                    <GridItem colSpan={3}>
                        {speedtest_url &&
                            <SpeedTestCard />
                        }
                    </GridItem>
                </Grid>
            </Container>
        </Fade>
    );
};
