import { FC, Fragment } from "react";
import { Table, Tr, Th, Td, Text, Flex } from "@chakra-ui/react";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";

export interface IInternetConfig {
  mask: string;
  gateway: string;
  dns1: string;
  dns2: string;
  pppoe_login: string;
  pppoe_password: string;
}

export interface IInternetConfigTable {
  configs: IInternetConfig[];
}

export const InternetConfigTable: FC<IInternetConfigTable> = ({ configs }) => (
  <Table variant="simple">
    <CustomedTableHead>
      <Tr>
        <Th>Maska</Th>
        <Th>Brama</Th>
        <Th>DNS 1 (podstawowy)</Th>
        <Th>DNS 2 (dodatkowy)</Th>
        <Th>PPPOE login</Th>
        <Th>PPPOE hasło</Th>
      </Tr>
    </CustomedTableHead>

    <CustomedTableBody>
      {configs.length ? (
        configs.map(
          ({ mask, gateway, dns1, dns2, pppoe_login, pppoe_password }) => (
            <Tr key={pppoe_login}>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {mask}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {gateway}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {dns1}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {dns2}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {pppoe_login}
                </Text>
              </Td>
              <Td>
                <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                  {pppoe_password}
                </Text>
              </Td>
            </Tr>
          )
        )
      ) : (
        <Tr>
          <Td>Brak danych</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      )}
    </CustomedTableBody>

    <MobileTableBody>
      {configs.length ? (
        configs.map(
          ({ mask, gateway, dns1, dns2, pppoe_login, pppoe_password }) => (
            <Fragment key={pppoe_login + "mobile"}>
              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      Maska
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {mask}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      Brama
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {gateway}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      DNS 1 (podstawowy)
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {dns1}
                    </Text>
                  </Flex>
                </Td>
              </Tr>

              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      DNS 2 (dodatkowy)
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {dns2}
                    </Text>
                  </Flex>
                </Td>
              </Tr>

              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      PPPOE login
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {pppoe_login}
                    </Text>
                  </Flex>
                </Td>
              </Tr>

              <Tr>
                <Td p="0px">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      PPPOE hasło
                    </Text>
                    <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                      {pppoe_password}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            </Fragment>
          )
        )
      ) : (
        <Tr>
          <Td>Brak danych</Td>
        </Tr>
      )}
    </MobileTableBody>
  </Table>
);
