import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

interface IBEAnnex {
  contractdate: string;
  contract_number: string;
  signdate: string;
  status_desc: string;
  html: string;
}

export const restructureAnnexDetails = ({
  contractdate,
  contract_number,
  signdate,
  status_desc,
  html,
}: IBEAnnex) => ({
  date: contractdate,
  name: contract_number,
  status: signdate === "0" ? false : !!signdate,
  status_desc,
  html,
});

const getAnnexDetails = async (id: string) =>
  getJSON(endpoints.annexes.details + "/" + id);

export const useAnnexDetails = (id: string) => {
  const { data, isLoading, error } = useQuery(
    "AnnexDetails" + id,
    () => getAnnexDetails(id),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureAnnexDetails(data?.data || {}),
    error,
  };
};
