import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { capitalizeFirstLetter } from "../shared/utils";

interface IBEStb {
  ext_linked: string;
  ipaddr: string;
  ipaddr_string: string;
  mac: string;
  modelid: string;
  model: string;
  serialnumber: string;
  status: string;
  status_desc: string;
}
export interface IBEPackageRecord {
  id: string;
  firmid: string;
  userid: string;
  iptv_accountid: string;
  actualtariffid: string;
  packageid: string;
  pkg_group: string;
  pkg_group_desc: string;
  pkg_name: string;
  stb_id: string;
  ext_pkg_id: string;
  ext_subscription_id: string;
  ext_order_id: string;
  date_start: string;
  date_end: string;
  date_deactivated: string;
  pricenetto: string;
  vat: string;
  pricebrutto: string;
  pricenetto_provider: string;
  vat_provider: string;
  pricebrutto_provider: string;
  self_service: string;
  promotion_active: string;
  promotionparent: string;
  promotiontime: string;
  creationdate: string;
  creatorid: string;
  stb?: IBEStb;
}

export const restructurePackagesData = (
  packages: IBEPackageRecord[],
  packageStatus: number
) =>
  packages
    ? packages.map(
        ({
          id,
          packageid,
          pkg_name,
          pkg_group_desc,
          self_service,
          pricebrutto,
          vat,
          stb,
        }) => ({
          name: pkg_name,
          description: "",
          type: capitalizeFirstLetter(pkg_group_desc),
          status: packageStatus,
          selfService: !!Number(self_service),
          amount: pricebrutto,
          vat,
          id,
          packageid,
          device: stb
            ? [
                "STB",
                stb.mac,
                stb.serialnumber,
                stb.model,
                `(IP: ${stb.ipaddr_string || "BRAK"})`,
              ].join("; ")
            : "",
        })
      )
    : [];

const getPackagesList = async (id: string) =>
  getJSON([endpoints.television.packages, id, "/packages"].join(""));

export const useTelevisionPackagesList = (id: string) => {
  const { data, isLoading, error } = useQuery(
    "TelevisionPackagesList" + id,
    () => getPackagesList(id),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: {
      active: restructurePackagesData(data?.data.active || [], 1),
      available: restructurePackagesData(data?.data.available || [], 0),
    },
    error,
  };
};
