import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

interface IBEPaymentChannels {
  id: string;
  availableCurrencies: string[];
  name: string;
  description: string;
  logoUrl: string;
}

const getUserPaymentChannels = async () =>
  getJSON(endpoints.user.payment.channels);

const restructureChannelsData = (channels: IBEPaymentChannels[]) =>
  channels.map(({ id, name, description, logoUrl }) => ({
    id,
    name,
    description,
    logo: logoUrl,
  }));

export const useUserPaymentChannels = () => {
  const { data, isLoading, error } = useQuery(
    "userPaymentChannels",
    getUserPaymentChannels,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureChannelsData(data?.data || []),
    // data: [...restructureChannelsData(data?.data || []), ...restructureChannelsData(data?.data || [])],
    error,
  };
};

