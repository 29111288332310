import { useQuery, useMutation } from "react-query";
import { getJSON, putJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";
import { start } from "repl";


interface updateParentalControlData {
    addressId: string;
    parentalControlData: object;
  }
  

const putParentalControlSettings = async ({addressId, parentalControlData}: updateParentalControlData) => putJSON(endpoints.user.parentalControl + '/' + addressId, parentalControlData);

export const useChangeParentalControlData = (successCallback?: Function) => {
    const [errors, setErrors] = useState<string[]>([])

    const { mutate, isLoading, isSuccess, isError, reset, status } = useMutation(
        putParentalControlSettings,
        {
            onSuccess: () => {
                if (successCallback) {
                    successCallback();
                }
            },
            onError: (error: any) => {
                setErrors(Object.values(error?.data.errors || {}));
            },
            onSettled: () => {
            },
        }
    )


    return {
        isLoading,
        isSuccess,
        isError,
        error: errors,
        status,
        saveParentalSettings: mutate,
    };
};
