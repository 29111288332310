import {useMutation} from "react-query";
import {putJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {useState} from "react";
import {LocalStorageService} from "../services/LocalStorageService";

const putUserChangePassword = (data: any) =>
    putJSON(endpoints.user.changePassword, {
        pass: data.pass,
        new_pass: data.new_pass,
        confirm_pass: data.confirm_pass
    });

export const useUserChangePassword = (setChangedPass?: Function) => {
    const {setToken} = LocalStorageService(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            putUserChangePassword,
            {
                onSuccess: () => {
                    if (setChangedPass) {
                        setToken('1', 'changepassword')
                        setChangedPass(true);
                    }
                },
                onError: (error: any) => {
                    setErrors(Object.values(error?.data.errors || {}));
                },
                onSettled: () => {
                },
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        userChangePassword: mutate,
        resetForm: reset,
    };
};
