import { chakra, Tab } from "@chakra-ui/react";

export const CustomTab = chakra(Tab, {
  baseStyle: {
    height: "100px",
    fontSize: "19px",
    color: "darkerPrimary",
    backgroundColor: "white",

    _selected: {
      borderBottom: "5px solid lightPrimary",
      color: "darkerPrimary",
      boxShadow: "none",
      "& > div": {
        color: "black",
      },
    },
  },
});
