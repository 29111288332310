import { Input, chakra } from "@chakra-ui/react";

export const CustomInput = chakra(Input, {
  baseStyle: {
    height: "35px",
    width: "100%",
    color: "darkPrimary",
    border: "1px solid darkPrimary",
    borderRadius: "10px",
    paddingBottom: "3px",
    _placeholder: {
      fontSize: "14px",
    },
    _disabled: {
      border: "1px solid lightGray",
      opacity: "0.6 ",
      color: "darkerPrimary",
      background: "lighterGray",
    },
  },
});
