import { FC, useContext } from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";

import { CustomMenuItem } from "./chakra";
import { useQueryClient } from "react-query";
import { LoginStateContext } from "../LoginState";
import {
  ChangePasswordModal,
  ChangeUserDataModal,
  ContactFormModal,
  MarketingAgreementsModal,
} from "..";
import { deleteJSON } from "../../../api/http";
import { endpoints } from "../../../api/endpoints";

export interface IUserMenu {
  name: string;
  imageUrl: string;
}

const deleteLogout = async () => deleteJSON(endpoints.auth);

export const UserMenu: FC<IUserMenu> = ({ name, imageUrl }) => {
  const { dispatch } = useContext(LoginStateContext);
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    deleteLogout();
    dispatch({ type: "NOT_LOGGED" });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={"full"}
        variant={"link"}
        cursor={"pointer"}
        minW={0}
      >
        <Center>
          {imageUrl && (
            <Image
              h="25px"
              w="25px"
              mr="10px"
              borderRadius="full"
              src={imageUrl}
            />
          )}
          <Text fontSize="16px" color="darkPrimary" mr="10px">
            {name}
          </Text>
          <FaChevronDown className="arrowIcon" />
        </Center>
      </MenuButton>
      <MenuList zIndex={3} p="0">
        <Box m={0}>
          <ChangeUserDataModal
            button={<CustomMenuItem my="2">Edytuj profil</CustomMenuItem>}
          />
          <MenuDivider m="0" />
          <ChangePasswordModal
            button={<CustomMenuItem my="2">Zmień hasło</CustomMenuItem>}
          />
          <MenuDivider m="0" />
          <MarketingAgreementsModal
            button={<CustomMenuItem my="2">Twoje zgody</CustomMenuItem>}
          />
          <MenuDivider m="0" />
          <ContactFormModal
            button={<CustomMenuItem my="2">Formularz kontaktowy</CustomMenuItem>}
          />
          <MenuDivider  m="0"/>
          <CustomMenuItem my="2" onClick={handleLogout}>Wyloguj</CustomMenuItem>
        </Box>
      </MenuList>
    </Menu>
  );
};
