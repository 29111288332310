import { colors } from "./Colors";
import { fontFamily } from "./FontFamily";
import { fontSize } from "./FontSize";
import { fontWeight } from "./FontWeight";
import ThemeProps from "./ITheme";

export const theme = {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
} as ThemeProps;
