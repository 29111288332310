import { FC, useState } from "react";
import { Table, Tr, Th, Td, Text, Flex, Spinner, Box } from "@chakra-ui/react";
import { usePaymentsList } from "../../../../hooks/usePaymentsList";
import { Pagination } from "../../Pagination";
import { IPagination } from "../../../../core/types";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";

const DEFAULT_PAGINATION: IPagination = {
  _limit: 10,
  _page: 1,
  _order: "desc",
};

export const PaymentsTable: FC = () => {
  const [pagination, setPagination] = useState<IPagination>(DEFAULT_PAGINATION);

  const { data, isLoading } = usePaymentsList({ pagination });

  const handlePageClick = (page: number) => {
    setPagination((oldState) => {
      const newState = { ...oldState, _page: page };
      return newState;
    });
  };

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignContent="center">
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="lightPrimary"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      <Table variant="simple">
        <CustomedTableHead>
          <Tr>
            <Th>Dokument</Th>
            <Th>Data wystawienia</Th>
            <Th>Kwota</Th>
          </Tr>
        </CustomedTableHead>

        <CustomedTableBody>
          {data.list.length ? (
            data.list.map(({ id, name, date, amount }) => (
              <Tr key={id + "-desktop"}>
                <Td>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {name}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {date}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="darkPrimary">
                    {amount} zł
                  </Text>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>Brak danych</Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          )}
        </CustomedTableBody>

        <MobileTableBody>
          {data.list.length ? (
            data.list.map(({ id, name, date, amount }) => (
              <Tr key={id + "-mobile"}>
                <Td p="5px 0">
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Box>
                      <Text fontSize="14px" fontWeight={600}>
                        {name}
                      </Text>
                      <Text fontSize="14px">{date}</Text>
                    </Box>
                    <Text fontSize="16px" color="darkPrimary" fontWeight={600}>
                      {amount} zł
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>Brak danych</Td>
            </Tr>
          )}
        </MobileTableBody>
      </Table>

      <Pagination
        totalCount={data?.total || 0}
        pageSize={pagination._limit}
        siblingCount={1}
        currentPage={pagination._page}
        onPageChange={handlePageClick}
      />
    </>
  );
};
