import { FC, useState } from "react";
import {
  Table,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Spinner,
  Collapse,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { useMobileBillingList } from "../../../../hooks/useMobileBillingList";
import { IPagination, IQueryOptions } from "../../../../core/types";
import { Pagination } from "../../Pagination";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";

export interface IMobileBilling {
  date: string;
  account: string;
  calledNumber: string;
  category: string;
  type: string;
  callTime: string;
  amount: string;
}

const DEFAULT_PAGINATION: IPagination = {
  _limit: 10,
  _page: 1,
  _order: "desc",
};

export const MobileBillingTable: FC<IQueryOptions> = ({ filters }) => {
  const [pagination, setPagination] = useState<IPagination>(DEFAULT_PAGINATION);
  const { data, isLoading } = useMobileBillingList({ pagination, filters });

  const handlePageClick = (page: number) => {
    setPagination((oldState) => {
      const newState = { ...oldState, _page: page };
      return newState;
    });
  };

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignContent="center">
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="lightPrimary"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <Collapse in={!isLoading} animateOpacity>
      <Table variant="simple">
        <CustomedTableHead>
          <Tr>
            <Th>Data</Th>
            <Th>Konto</Th>
            <Th>Numer docelowy</Th>
            <Th>Kategoria</Th>
            <Th>Czas połączenia</Th>
            <Th>Transfer</Th>
            <Th>Kwota brutto</Th>
          </Tr>
        </CustomedTableHead>

        <CustomedTableBody>
          {data.list.length ? (
            data.list.map(
              ({
                id,
                date,
                account,
                calledNumber,
                category,
                callTime,
                transfer,
                amount,
              }) => (
                <Tr key={id}>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {date}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {account}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack" fontWeight={600}>
                      {calledNumber}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {category}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {callTime}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {transfer}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {amount}
                    </Text>
                  </Td>
                </Tr>
              )
            )
          ) : (
            <Tr>
              <Td colSpan={7}>Brak danych</Td>
            </Tr>
          )}
        </CustomedTableBody>

        <MobileTableBody>
          {data.list.length ? (
            data.list.map(
              ({
                id,
                date,
                account,
                calledNumber,
                category,
                callTime,
                transfer,
                amount,
              }) => (
                <Tr
                  key={id + account + calledNumber + date + callTime + "mobile"}
                >
                  <Td p="5px 0">
                    <Accordion allowToggle border="none">
                      <AccordionItem border="none">
                        <AccordionButton
                          display="flex"
                          justifyContent={"space-between"}
                          alignItems="center"
                          style={{ boxShadow: "none" }}
                        >
                          <Box>
                            <Text fontSize="14px">{calledNumber}</Text>
                            <Text fontSize="14px" fontWeight={600}>
                              {date}
                            </Text>
                          </Box>
                          <Text
                            fontSize="16px"
                            color="darkPrimary"
                            fontWeight={600}
                            ml="auto"
                            mr="10px"
                          >
                            {amount}
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                          <Table>
                            <MobileTableBody>
                              <Tr>
                                <Td p="5px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontSize="14px" color="darkerPrimary">
                                      Numer docelowy
                                    </Text>
                                    <Text
                                      fontSize="14px"
                                      color="darkerPrimary"
                                      fontWeight="600"
                                    >
                                      {calledNumber}
                                    </Text>
                                  </Flex>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td p="5px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontSize="14px" color="darkerPrimary">
                                      Kategoria
                                    </Text>
                                    <Text
                                      fontSize="14px"
                                      color="darkerPrimary"
                                      fontWeight="600"
                                      textAlign="right"
                                      ml="10px"
                                    >
                                      {category}
                                    </Text>
                                  </Flex>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td p="5px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontSize="14px" color="darkerPrimary">
                                      Transfer
                                    </Text>
                                    <Text
                                      fontSize="14px"
                                      color="darkerPrimary"
                                      fontWeight="600"
                                    >
                                      {transfer}
                                    </Text>
                                  </Flex>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td p="5px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontSize="14px" color="darkerPrimary">
                                      Czas połączenia
                                    </Text>
                                    <Text
                                      fontSize="14px"
                                      color="darkerPrimary"
                                      fontWeight="600"
                                    >
                                      {callTime}
                                    </Text>
                                  </Flex>
                                </Td>
                              </Tr>
                            </MobileTableBody>
                          </Table>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Td>
                </Tr>
              )
            )
          ) : (
            <Tr>
              <Td>Brak danych</Td>
            </Tr>
          )}
        </MobileTableBody>
      </Table>
      <Pagination
        totalCount={data.total || 0}
        pageSize={pagination._limit}
        siblingCount={1}
        currentPage={pagination._page}
        onPageChange={handlePageClick}
      />
    </Collapse>
  );
};
