import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getContractsListWithAnnexes = async () =>
  getJSON(endpoints.contracts.withAnnexes);

export const useContractsListWithAnnexes = () => {
  const { data, isLoading, error } = useQuery(
    "contractsListWithAnnexes",
    getContractsListWithAnnexes,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
