import { FC } from "react";
import { Table, Tr, Th, Td, Text, Flex } from "@chakra-ui/react";
import {
  CustomedTableBody,
  CustomedTableHead,
  MobileCheckTag,
  MobileCloseTag,
  MobileTableBody,
  StatusBadge,
} from "../..";
import { useServicesList } from "../../../../hooks/useServicesList";
import { IBEServices, IService } from "../../../../core/types/Finance";
import { restructureDate } from "../../../utils";

export const restructureServices = (services: IBEServices) =>
  services
    ? services.list.map(
        ({
          active,
          contract_number,
          contractdate,
          locationcity,
          locationlocal,
          locationstreetname,
          locationstreetno,
          paymentperiodname,
          pricebrutto,
          pricenetto,
          servicename,
          vatrate,
        }) => ({
          name: servicename,
          date: restructureDate(contractdate),
          contractName: contract_number,
          address:
            locationcity &&
            [
              locationcity,
              locationstreetname,
              locationstreetno + "/" + locationlocal,
            ].join(", "),
          status: !!Number(active),
          nettoAmount: pricenetto,
          vat: vatrate,
          bruttoAmount: pricebrutto,
          subscribtionType: paymentperiodname,
        })
      )
    : [];

export const ServicesTable: FC = () => {
  const rawServicess = useServicesList().data;
  const services: IService[] = restructureServices(rawServicess);

  return (
    <Table variant="simple">
      <CustomedTableHead>
        <Tr>
          <Th>Nazwa usługi i taryfy</Th>
          <Th>Umowa</Th>
          <Th>Status</Th>
          <Th>Kwota netto</Th>
          <Th>VAT</Th>
          <Th>Kwota brutto</Th>
          <Th>Okres płatności</Th>
        </Tr>
      </CustomedTableHead>

      <CustomedTableBody>
        {services.length ? (
          services.map(
            (
              {
                name,
                address,
                contractName,
                date,
                status,
                nettoAmount,
                vat,
                bruttoAmount,
                subscribtionType,
              },
              index
            ) => (
              <Tr key={name + index}>
                <Td>
                  <Text fontSize="14px" color="basicBlack" fontWeight={600}>
                    {name}
                  </Text>
                  <Text fontSize="14px" color="basicBlack">
                    {address}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="basicBlack" fontWeight={600}>
                    {contractName}
                  </Text>
                  <Text fontSize="14px" color="basicBlack">
                    ({date})
                  </Text>
                </Td>
                <Td>
                  {status ? (
                    <StatusBadge label="Aktywna" status="active" />
                  ) : (
                    <StatusBadge label="Nieaktywna" status="inactive" />
                  )}
                </Td>
                <Td>
                  <Text fontSize="14px" color="darkPrimary">
                    {nettoAmount} zł
                  </Text>
                </Td>

                <Td>
                  <Text fontSize="14px" color="darkPrimary">
                    {vat} %
                  </Text>
                </Td>
                <Td>
                  <Text fontSize="14px" color="darkPrimary">
                    {bruttoAmount} zł
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize="14px"
                    color="darkPrimary"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {subscribtionType}
                  </Text>
                </Td>
              </Tr>
            )
          )
        ) : (
          <Tr>
            <Td>Brak danych</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </CustomedTableBody>
      <MobileTableBody>
        {services.length ? (
          services.map(({ name, date, bruttoAmount, status }, index) => (
            <Tr key={name + date + "mobile" + index}>
              <Td p="5px 0">
                <Flex justifyContent="space-between" alignItems="center">
                  {status ? <MobileCheckTag /> : <MobileCloseTag />}
                  <Text fontSize="14px" fontWeight={600} m="0px auto 0px 10px">
                    {name}
                  </Text>
                  <Text fontSize="16px" color="darkPrimary" fontWeight={600}>
                    {bruttoAmount} zł
                  </Text>
                </Flex>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
          </Tr>
        )}
      </MobileTableBody>
    </Table>
  );
};
