import { Box, chakra } from "@chakra-ui/react";

const baseBadge = {
  width: "90px",
  height: "24px",
  padding: "2px",
  textAlign: "center",
  borderRadius: "6px",
  fontSize: "12px",
  color: "white",
};

export const SuccessBadge = chakra(Box, {
  baseStyle: {
    ...baseBadge,
    backgroundColor: "darkGreen",
  },
});

export const DangerBadge = chakra(Box, {
  baseStyle: {
    ...baseBadge,
    backgroundColor: "darkRed",
  },
});
