import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { FC } from "react";

export interface IDetail {
  color: string;
  textSize: string;
  title: string;
  data?: string;
  margin?: string;
  textBold?: boolean;
}

export const Detail: FC<IDetail & FlexProps> = ({
  color,
  textSize,
  title,
  data,
  m,
  margin,
  textBold = true,
  children,
}) => (
  <Flex flexDir="column" m={m || margin || "0 15px 20px 0"} zIndex="2">
    <Text fontSize="14px" color="lightGray" textTransform="uppercase">
      {title}
    </Text>
    <Text
      fontSize={["22px", "22px", textSize, textSize]}
      fontWeight={textBold ? 600 : 500}
      color={color}
    >
      {children || data}
    </Text>
  </Flex>
);
