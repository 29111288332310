import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Card, Detail } from "../..";

export interface ISettlementsCard {
  paymentAccount: string;
  userBalance: string;
}

export const SettlementsCard: FC<ISettlementsCard> = ({
  paymentAccount,
  userBalance,
}) => {

  return (
    <Card title="Rozliczenia">
      <Detail title="NUMER KONTA DO WPŁAT" textSize="24px" color="black">
        {paymentAccount || "Brak danych"}
      </Detail>
      <Flex justifyContent="space-between">
        <Box mr="10px">
          <Detail title="Twoje saldo" textSize="24px" color={parseInt(userBalance) > 0 ? "lightPrimary" : "darkRed"}>
            {userBalance}
          </Detail>
        </Box>
      </Flex>
    </Card>
  );
};
