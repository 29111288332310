import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

export interface IBETelevisionPackage {
  id: string;
  userid: string;
  iptv_program: string;
  status: string;
  tariffname: string;
  count_stbs: string;
  count_orders: string;
  total_netto: string;
  total_brutto: string;
}

export interface IBETelevision {
  list: IBETelevisionPackage[];
  total: number;
}

export const restructureTelevisionData = (television: IBETelevision) =>
  television
    ? television.list.map(
        ({ tariffname, count_stbs, count_orders, total_brutto, id }) => ({
          name: tariffname,
          deviceCount: count_stbs,
          packetCount: count_orders,
          amount: total_brutto,
          id,
        })
      )
    : [];

const getTelevisionList = async () => getJSON(endpoints.television.list);

export const useTelevisionList = () => {
  const { data, isLoading, error } = useQuery(
    "televisionList",
    getTelevisionList,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureTelevisionData(data?.data),
    error,
  };
};
