import { Textarea, chakra } from "@chakra-ui/react";

export const CustomTextArea = chakra(Textarea, {
  baseStyle: {
    width: "100%",
    color: "darkPrimary",
    border: "1px solid darkPrimary",
    borderRadius: "10px",
    paddingBottom: "3px",
    _placeholder: {
      fontSize: "14px",
    },
  },
});
