import { chakra } from "@chakra-ui/react";

export const CustomIcon = chakra("div", {
  baseStyle: {
    background: "white",
    color: "lightPrimary",
    transition: " background 0.6s",
    fontSize: ["20px", "20px", "28px"],
    mr: "10px",
    _active: {
      color: "darkPrimary",
    },
    _hover: {
      color: "lighterPrimary",
    },
  },
});
