import {
  Box,
  Button,
  Container,
  Fade,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, FC, useState } from "react";
import { FaAngleRight } from "react-icons/fa";

import {
  Banner,
  Card,
  ContactFormModal,
  CustomedSelect,
  Detail,
  PhoneNumbersTable,
  PageHeader,
  MobileBillingTable,
} from "../../shared/components";
import { CustomCardImage } from "../../shared/components/Card/chakra";
import bannerImage from "../../img/bannerTelephone/telefonia_top.jpg";
import spraksImage from "../../img/services/Sparks.png";
import { generateOption, generateOptions } from "../../shared/utils";
import { IQueryOptions } from "../../core/types";
import { BannerClass } from "../../shared/components/Banner/utils";
import { useUserData } from "../../hooks/useUserData";
import {
  useMobileBillingCategoryDict,
  useMobileBillingMonthDict,
  useMobileBillingTypeDict,
} from "../../hooks/useMobileBillingDict";
import { useMobileBillingPhones } from "../../hooks/useMobileBillingPhones";
import { useMobileBillingSummary } from "../../hooks/useMobileBillingSummary";
import { downloadFromUrl } from "../../api/http";
import { endpoints } from "../../api/endpoints";
import { env } from "../../env";

const mobileSelectMargin = ["0px", "0px", "0 15px 0 0 "];

export const MobileTelephony: FC = () => {
  const [filters, setFilters] = useState<IQueryOptions["filters"]>({});

  const { data, isLoading } = useMobileBillingSummary({ filters });
  const userData = useUserData();
  const phonesData = useMobileBillingPhones();

  const categoriesDict = useMobileBillingCategoryDict({ filters });
  const monthDict = useMobileBillingMonthDict();
  const typeDict = useMobileBillingTypeDict();

  const bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno);

  const handleSelectChange = (name: string, value: string) => {
    setFilters((oldState) => {
      let newState = { ...oldState };
      if (value) {
        newState[name] = value;
      } else {
        delete newState[name];
      }

      if (name === "traffic_types_id") {
        delete newState["traffic_categories_id"];
      }

      return newState;
    });
  };

  const handleDownload = () => {
    downloadFromUrl(
      endpoints.mobile.download +
        "?calldate_month=" +
        (filters && filters["calldate_month"]) ||
        (Object.keys(monthDict.data || {})[0] as string)
    );
  };

  if (!data) {
    return (
      <Flex h="100%" justifyContent="center" alignContent="center" mt="15%">
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="lightPrimary"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      <Banner
        bannerImage={bannerImage}
        // extraContent={<SendFaxModal />}
        {...bannerDetails}
      />
      <Fade in={true}>
        <Container maxWidth={"container.xl"} flexDir={"column"}>
          <PageHeader title="Telefonia" onlyMobile />
          <Grid templateColumns={"repeat(2, 1fr)"} gap={30} mb="20px">
            {/* Telefonia */}
            <GridItem colSpan={[2, 2, 1]}>
              <Card title="Telefonia mobilna">
                <Flex flexDir="column" zIndex={2} h="full">
                  <Box zIndex={2}>
                    <Text
                      fontSize="14px"
                      color="lightGray"
                      textTransform="uppercase"
                    >
                      Oferta
                    </Text>
                    <Text fontSize="24px" color="basicBlack" fontWeight="600">
                      Telefon mobilny, jakiego potrzebujesz na warunkach, jakich
                      oczekujesz.
                    </Text>
                    <Text fontSize="14px" color="basicBlack" zIndex={2}>
                      Dużo dzwonisz czy więcej czasu spędzasz przeglądając
                      internet? A może potrzebujesz tylko nielimitowanych
                      połączeń? W naszej prostej i przejrzystej ofercie na pewno
                      znajdziesz odpowiedni pakiet dla siebie!
                    </Text>
                  </Box>
                  <ContactFormModal
                    button={
                      <Button
                        size="sm"
                        rightIcon={<FaAngleRight />}
                        mt="5px"
                        alignSelf="flex-start"
                      >
                        Sprawdź
                      </Button>
                    }
                    action="contract"
                  />
                </Flex>
                <CustomCardImage
                  src={spraksImage}
                  height="102px"
                  margin="10px"
                />
              </Card>
            </GridItem>

            <GridItem colSpan={[2, 2, 1]}>
              <Card title="Numery telefoniczne">
                <PhoneNumbersTable phones={phonesData?.data || []} />
              </Card>
            </GridItem>

            <GridItem colSpan={2}>
              <Card title="Filtr połączeń">
                <Flex
                  flexDir={["column", "column", "row"]}
                  flexWrap={["wrap", "wrap", "nowrap"]}
                >
                  <CustomedSelect
                    margin={mobileSelectMargin}
                    label="Data"
                    placeholder={
                      Object.values(monthDict.data || {})[0] as string
                    }
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      handleSelectChange("calldate_month", event.target.value)
                    }
                  >
                    {generateOptions(monthDict.data, 1)}
                  </CustomedSelect>

                  <CustomedSelect
                    margin={mobileSelectMargin}
                    label="Konto"
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      handleSelectChange("ano", event.target.value)
                    }
                  >
                    {phonesData.data.map(({ accountcode, phone }) =>
                      generateOption(phone, accountcode)
                    )}
                  </CustomedSelect>

                  <CustomedSelect
                    margin={mobileSelectMargin}
                    label="Typ"
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      handleSelectChange("traffic_types_id", event.target.value)
                    }
                  >
                    {generateOptions(typeDict.data)}
                  </CustomedSelect>

                  <CustomedSelect
                    margin={mobileSelectMargin}
                    label="Kategoria"
                    isDisabled={filters?.["traffic_types_id"] === undefined}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      handleSelectChange(
                        "traffic_categories_id",
                        event.target.value
                      )
                    }
                  >
                    {generateOptions(categoriesDict.data)}
                  </CustomedSelect>

                  <Flex
                    justifyContent="center"
                    w="100%"
                    mt="10px"
                    flexDir={["column-reverse", "column-reverse", "row"]}
                    display={["flex", "flex", "none"]}
                  >
                    <Button
                      backgroundColor="darkerPrimary"
                      _hover={{ backgroundColor: "lightPrimary" }}
                      color="white"
                      p="10px 30px"
                      size="sm"
                      h="35px"
                    >
                      Filtruj
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>

            {/* Podsumowanie */}
            <GridItem colSpan={2}>
              <Card title="Podsumowanie">
                {isLoading ? (
                  <Flex h="100%" justifyContent="center" alignContent="center">
                    <Spinner
                      thickness="4px"
                      speed="0.8s"
                      emptyColor="gray.200"
                      color="lightPrimary"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Grid templateColumns={"repeat(7, 1fr)"} gap={15}>
                    <GridItem colSpan={[7, 7, 7, 4]}>
                      <Flex
                        flexDir="row"
                        flexWrap="wrap"
                        justify="space-between"
                        backgroundColor="backgroundPrimary"
                        borderRadius="10px"
                        padding="20px 15px 0px 15px"
                      >
                        <Box>
                          <Detail
                            title="Łączny czas połączeń"
                            textSize="24px"
                            color="lightPrimary"
                          >
                            {data.duration_total}
                          </Detail>{" "}
                          <Detail
                            title="Darmowe"
                            textSize="24px"
                            color="black"
                            textBold={false}
                          >
                            {data.duration_free}
                          </Detail>
                        </Box>
                        <Box>
                          <Detail
                            title="Łączny transfer danych"
                            textSize="24px"
                            color="lightPrimary"
                          >
                            {data.datatransfer_total}
                          </Detail>
                          <Detail
                            title="Darmowy transfer"
                            textSize="24px"
                            color="black"
                            textBold={false}
                          >
                            {data.datatransfer_free}
                          </Detail>
                        </Box>
                        <Box>
                          {" "}
                          <Detail
                            title="Łączny ilość sms/mms"
                            textSize="24px"
                            color="lightPrimary"
                          >
                            {data.msg_total} wiadomości
                          </Detail>{" "}
                          <Detail
                            title="Darmowe sms/mms"
                            textSize="24px"
                            color="black"
                            textBold={false}
                          >
                            {data.msg_free} wiadomości
                          </Detail>
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={[7, 7, 7, 3]}>
                      <Flex
                        flexDir="row"
                        flexWrap="wrap"
                        justify="space-between"
                        align="center"
                        backgroundColor="backgroundPrimary"
                        borderRadius="10px"
                        padding="20px 15px 0px 15px"
                        height="100%"
                      >
                        <Detail
                          title="Łączny koszt"
                          textSize="24px"
                          color="lightPrimary"
                        >
                          {data.total_brutto} zł
                        </Detail>
                        <Detail
                          title="Netto"
                          textSize="24px"
                          color="black"
                          textBold={false}
                        >
                          {data.total_netto} zł
                        </Detail>
                        <Detail
                          title="Kwota Vat"
                          textSize="24px"
                          color="black"
                          textBold={false}
                        >
                          {data.total_vat} zł
                        </Detail>
                      </Flex>
                    </GridItem>
                  </Grid>
                )}
              </Card>
            </GridItem>

            {/* Billing */}
            <GridItem colSpan={2}>
              <Box minHeight={["0px", "0px", "700px"]}>
                <Card
                  title="Billing"
                  extraContent={
                    <Box display={["none", "none", "flex"]}>
                      {/* <Button
                      backgroundColor="darkerPrimary"
                      _hover={{ backgroundColor: "lightPrimary" }}
                      color="white"
                      mr="15px"
                    >
                      Drukuj billing
                    </Button> */}
                      <Button
                        backgroundColor="darkerPrimary"
                        _hover={{ backgroundColor: "lightPrimary" }}
                        color="white"
                        onClick={handleDownload}
                      >
                        Pobierz billing
                      </Button>
                    </Box>
                  }
                >
                  <MobileBillingTable filters={filters} />
                </Card>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </Fade>
    </>
  );
};
