import { useQuery, useMutation } from "react-query";
import { getJSON, putJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";
import { start } from "repl";
import { useParams } from "react-router-dom";


interface IParentalControlSettings {
    access: string;
    workhour_type: string;
    workhour_start: string;
    workhour_end: string;
    
}

export const STATUS = {
    ON: 'włączony',
    OFF: 'wyłączony'
}

export const restructureParentalControlData = ({
    access,
    workhour_type,
    workhour_start,
    workhour_end,
}: IParentalControlSettings) => ({
    status: access === '1' ? STATUS.ON : STATUS.OFF,
    // type: workhour_type === '1' ? STATUS.ON : STATUS.OFF,
    type: workhour_type,
    start: workhour_start,
    end: workhour_end,
});

const getParentalControlSettings = async (id: string) => getJSON(endpoints.user.parentalControl + '/' + id);

export interface IParentalControlData {
    id: string,
    successCallback?: Function
}

export const useParentalControlData = ({id, successCallback} : IParentalControlData) => {
    const [errors, setErrors] = useState<string[]>([]),

        { data, refetch, isLoading, error } = useQuery("parentalControlSettings" + id, () => getParentalControlSettings(id!), {
            staleTime: Infinity,
        });

    return {
        isLoading,
        data: restructureParentalControlData(data?.data || {}),
        error,
        refetch,
    };
};
