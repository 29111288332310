import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useRegulation } from "../../../../hooks/useRegulation";
import { useUserData } from "../../../../hooks/useUserData";
import { useIsComponentMounted } from "../../../../hooks/useIsMounted";


export interface IRegulationModal {
    modalHeader?: string;
    noButton?: boolean;
    onCloseModal?: Function;
}

export const RegulationModal: FC<IRegulationModal> = ({
    modalHeader,
    noButton,
    onCloseModal
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure(),
        onFormClose = () => {
            if (noButton) {
                onClose();
            }
            if (onCloseModal) {
                onCloseModal();
            }
        },
        { data, acceptRegulation } = useRegulation(onClose),
        onSubmit = () => {
            acceptRegulation();
        };
   
    useEffect(() => {
        if (!isOpen) {
            onOpen();
        }
    }, [data]);

    return (
        <Modal isOpen={isOpen} onClose={onFormClose}>
            <ModalOverlay onClick={onFormClose} />
            <ModalContent minW={["100%", "100%", "500px"]}
                minH={["100%", "100%", "initial"]}
                m={["0px", "0px", "60px 0px"]}
                borderRadius={["0px", "0px", "10px"]}>
                <ModalHeader color="darkPrimary">{modalHeader ? modalHeader : 'Zmiana regulaminu'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {data &&
                        <div dangerouslySetInnerHTML={{ __html: data.html }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="center"
                        w="100%"
                        flexDir={["column-reverse", "column-reverse", "row"]}>
                        {!noButton &&
                            <Button backgroundColor="lightPrimary"
                                _hover={{ backgroundColor: "lighterPrimary" }}
                                color="white"
                                p="10px 30px"
                                size="sm"
                                h="35px"
                                onClick={() =>{
                                    onFormClose()
                                    onSubmit()
                                }}>
                                Akceptuję
                            </Button>
                        }
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
