import { useMutation, useQuery, useQueryClient } from "react-query";
import { extendedPostJSON, getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

const getContactFormDict = async () => getJSON(endpoints.contact.dict.subjects);

export const useContactFormDict = () => {
  const { data, isLoading, error } = useQuery(
    "contactFormDict",
    getContactFormDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const postcontactForm = (data: any) =>
  extendedPostJSON(endpoints.contact.form, data);

export const useContactForm = () => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isSuccess, isError, reset } = useMutation(
    postcontactForm,
    {
      onSuccess: () => {},
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => {
        queryClient.invalidateQueries("contact");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    errors,
    contactForm: mutate,
    resetContactForm: reset,
  };
};
