import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { stringify, ParsedUrlQueryInput } from "querystring";
import { IQueryOptions } from "../core/types";

const getInvoicesList = async (stringParams: string) =>
  getJSON(
    [endpoints.invoices.list, stringParams && "?", stringParams].join("")
  );

export const useInvoicesList = ({ pagination, filters }: IQueryOptions) => {
  let urlParams = "";

  if (pagination)
    urlParams = stringify(pagination as unknown as ParsedUrlQueryInput);

  if (filters && pagination) urlParams += "&";
  if (filters) urlParams += stringify(filters);

  const { data, isLoading, error } = useQuery(
    "invoicesList" + urlParams,
    () => getInvoicesList(urlParams),
    {
      refetchOnWindowFocus: true,
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};
