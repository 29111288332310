import { Button, chakra } from "@chakra-ui/react";

export const CustomButton = chakra(Button, {
  baseStyle: {
    background: "lightPrimary",
    color: "white",
    transition: " background 0.6s",
    _hover: {
      background: "lighterPrimary",
    },
  },
});
