import React, {FC} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {QueryClient, QueryClientProvider} from "react-query";
import {ChakraProvider, extendTheme, ThemeConfig} from "@chakra-ui/react";
import {colors} from "./theme/Colors";
import {theme} from "./theme";

import {LoginState} from "./shared/components/LoginState";
import {MainRouter} from "./routing";
import "./index.css";

const config: ThemeConfig = {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    chakraTheme = extendTheme({
        colors,
        config: config,
    }),
    queryClient = new QueryClient(),
    
    Ebok: FC = () => (
        <ThemeProvider theme={theme}>
            <ChakraProvider theme={chakraTheme}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <LoginState>
                            <MainRouter/>
                        </LoginState>
                    </BrowserRouter>
                </QueryClientProvider>
            </ChakraProvider>
        </ThemeProvider>
    );

ReactDOM.render(
    <React.StrictMode>
        <Ebok/>
    </React.StrictMode>,
    document.getElementById("root")
);
