import {
    Box,
    Button,
    Container,
    Fade,
    Flex,
    Grid,
    GridItem,
    Spinner,
    Text,
} from "@chakra-ui/react";
import {ChangeEvent, FC, useState} from "react";
import {FaAngleRight} from "react-icons/fa";

import {
    Banner,
    BillingTable,
    Card,
    ContactFormModal,
    CustomedSelect,
    Detail,
    PhoneNumbersTable,
    PageHeader,
} from "../../shared/components";
import {CustomCardImage} from "../../shared/components/Card/chakra";
import bannerImage from "../../img/bannerTelephone/telefonia_top.jpg";
import spraksImage from "../../img/services/Sparks.png";
import {useBillingSummary} from "../../hooks/useBillingsSummary";
import {
    useBillingContextDict,
    useBillingCountryDict,
    useBillingDispositionDict,
    useBillingMonthDict,
} from "../../hooks/useBillingDict";
import {generateOption, generateOptions} from "../../shared/utils";
import {IQueryOptions} from "../../core/types";
import {BannerClass} from "../../shared/components/Banner/utils";
import {useUserData} from "../../hooks/useUserData";
import {useBillingPhones} from "../../hooks/useBillingPhones";
import {useFirm} from "../../hooks/useFirm";
import {downloadFromUrl} from "../../api/http";
import {endpoints} from "../../api/endpoints";
import { env } from "../../env";

const mobileSelectMargin = ["0px", "0px", "0 15px 0 0 "];

export const Telephony: FC = () => {
    const [filters, setFilters] = useState<IQueryOptions["filters"]>({}),

        {data} = useBillingSummary({filters}),
        phonesData = useBillingPhones(),
        userData = useUserData(),

        contextDict = useBillingContextDict(),
        countryDict = useBillingCountryDict(),
        dispositionDict = useBillingDispositionDict(),
        monthDict = useBillingMonthDict(),
        {name} = useFirm().data,

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno),

        handleSelectChange = (name: string, value: string) => {
            setFilters((oldState) => {
                let newState = {...oldState};
                if (value) {
                    newState[name] = value;
                } else {
                    delete newState[name];
                }

                return newState;
            });
        },

        handleDownload = () => {
            downloadFromUrl(
                endpoints.telephony.download +
                "?calldate_month=" +
                (filters && filters["calldate_month"]) ||
                (Object.keys(monthDict.data || {})[0] as string)
            );
        };

    if (
        !data ||
        contextDict.isLoading ||
        dispositionDict.isLoading ||
        dispositionDict.isLoading ||
        monthDict.isLoading
    ) {
        return (
            <Flex h="100%" justifyContent="center" alignContent="center" mt="15%">
                <Spinner
                    thickness="4px"
                    speed="0.8s"
                    emptyColor="gray.200"
                    color="lightPrimary"
                    size="xl"
                />
            </Flex>
        );
    }

    return (
        <>
            <Banner
                bannerImage={bannerImage}
                // extraContent={<SendFaxModal />}
                {...bannerDetails}
            />
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title="Telefonia" onlyMobile/>
                    <Grid templateColumns={"repeat(2, 1fr)"} gap={30} mb="20px">
                        {/* Telefonia */}
                        <GridItem colSpan={[2, 2, 1]}>
                            <Card title="Telefonia">
                                <Flex flexDir="column" zIndex={2} h="full">
                                    <Box zIndex={2}>
                                        <Text
                                            fontSize="14px"
                                            color="lightGray"
                                            textTransform="uppercase"
                                        >
                                            Oferta
                                        </Text>
                                        <Text fontSize="24px" color="basicBlack" fontWeight="600">
                                            Przenieś numer do {name}
                                        </Text>
                                        <Text fontSize="14px" color="basicBlack" zIndex={2}>
                                            Rozmawiaj stacjonarnie i korzystaj z wielu udogodnień.
                                            Zapoznaj się z ofertą telefonu stacjonarnego i wybierz
                                            odpowiedni pakiet dla siebie.
                                        </Text>
                                    </Box>
                                    <ContactFormModal
                                        button={
                                            <Button
                                                size="sm"
                                                rightIcon={<FaAngleRight/>}
                                                mt="5px"
                                                alignSelf="flex-start"
                                            >
                                                Sprawdź
                                            </Button>
                                        }
                                        action="contract"
                                    />
                                </Flex>
                                <CustomCardImage
                                    src={spraksImage}
                                    height="102px"
                                    margin="10px"
                                />
                            </Card>
                        </GridItem>

                        {/* Urządzenia VOIP */}
                        <GridItem colSpan={[2, 2, 1]}>
                            <Card title="Numery telefoniczne">
                                <PhoneNumbersTable phones={phonesData?.data || []}/>
                            </Card>
                        </GridItem>

                        {/* Filt połączeń */}
                        <GridItem colSpan={2}>
                            <Card title="Filtr połączeń">
                                <Flex flexDir={["column", "column", "row"]} flexWrap="wrap">
                                    <CustomedSelect
                                        margin={mobileSelectMargin}
                                        label="Data"
                                        placeholder={
                                            Object.values(monthDict.data || {})[0] as string
                                        }
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            handleSelectChange("calldate_month", event.target.value)
                                        }
                                    >
                                        {generateOptions(monthDict.data, 1)}
                                    </CustomedSelect>

                                    <CustomedSelect
                                        margin={mobileSelectMargin}
                                        label="Konto"
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            handleSelectChange("accountcode", event.target.value)
                                        }
                                    >
                                        {phonesData.data.map(({accountcode, phone}) =>
                                            generateOption(phone, accountcode)
                                        )}
                                    </CustomedSelect>

                                    <CustomedSelect
                                        margin={mobileSelectMargin}
                                        label="Lokalizacja"
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            handleSelectChange("international", event.target.value)
                                        }
                                    >
                                        {generateOptions(countryDict.data)}
                                    </CustomedSelect>

                                    <CustomedSelect
                                        margin={mobileSelectMargin}
                                        label="Rodzaj"
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            handleSelectChange("dcontext", event.target.value)
                                        }
                                    >
                                        {generateOptions(contextDict.data)}
                                    </CustomedSelect>
                                    <CustomedSelect
                                        margin={mobileSelectMargin}
                                        label="Status"
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            handleSelectChange("disposition", event.target.value)
                                        }
                                    >
                                        {generateOptions(dispositionDict.data)}
                                    </CustomedSelect>

                                    <Flex
                                        justifyContent="center"
                                        w="100%"
                                        mt="10px"
                                        flexDir={["column-reverse", "column-reverse", "row"]}
                                        display={["flex", "flex", "none"]}
                                    >
                                        <Button
                                            backgroundColor="darkerPrimary"
                                            _hover={{backgroundColor: "lightPrimary"}}
                                            color="white"
                                            p="10px 30px"
                                            size="sm"
                                            h="35px"
                                        >
                                            Filtruj
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </GridItem>

                        {/* Podsumowanie */}
                        <GridItem colSpan={2}>
                            <Card title="Podsumowanie">
                                <Grid templateColumns={"repeat(7, 1fr)"} gap={15}>
                                    <GridItem colSpan={[7, 7, 7, 4]}>
                                        <Flex
                                            flexDir="row"
                                            flexWrap="wrap"
                                            justify="space-between"
                                            backgroundColor="backgroundPrimary"
                                            borderRadius="10px"
                                            padding="20px 15px 0px 15px"
                                        >
                                            <Detail
                                                title="Łączny czas połączeń"
                                                textSize="24px"
                                                color="lightPrimary"
                                            >
                                                {data.billsec_total}
                                            </Detail>
                                            <Detail
                                                title="Przychodzące"
                                                textSize="24px"
                                                color="black"
                                                textBold={false}
                                            >
                                                {data.billsec_incoming}
                                            </Detail>
                                            <Detail
                                                title="Wychodzące"
                                                textSize="24px"
                                                color="black"
                                                textBold={false}
                                            >
                                                {data.billsec_outgoing}
                                            </Detail>
                                            <Detail
                                                title="Darmowe"
                                                textSize="24px"
                                                color="black"
                                                textBold={false}
                                            >
                                                {data.billsec_free}
                                            </Detail>
                                        </Flex>
                                    </GridItem>
                                    <GridItem colSpan={[7, 7, 7, 3]}>
                                        <Flex
                                            flexDir="row"
                                            flexWrap="wrap"
                                            justify="space-between"
                                            backgroundColor="backgroundPrimary"
                                            borderRadius="10px"
                                            padding="20px 15px 0px 15px"
                                        >
                                            <Detail
                                                title="Łączny koszt"
                                                textSize="24px"
                                                color="lightPrimary"
                                            >
                                                {data.total_brutto}
                                            </Detail>
                                            <Detail
                                                title="Netto"
                                                textSize="24px"
                                                color="black"
                                                textBold={false}
                                            >
                                                {data.total_netto}
                                            </Detail>
                                            <Detail
                                                title="Kwota Vat"
                                                textSize="24px"
                                                color="black"
                                                textBold={false}
                                            >
                                                {data.total_vat}
                                            </Detail>
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </Card>
                        </GridItem>

                        {/* Billing */}
                        <GridItem colSpan={2}>
                            <Box minHeight={["0px", "0px", "700px"]}>
                                <Card
                                    title="Billing"
                                    extraContent={
                                        <Box>
                                            <Button
                                                backgroundColor="darkerPrimary"
                                                _hover={{backgroundColor: "lightPrimary"}}
                                                color="white"
                                                onClick={handleDownload}
                                            >
                                                Pobierz billing
                                            </Button>
                                        </Box>
                                    }
                                >
                                    <BillingTable filters={filters}/>
                                </Card>
                            </Box>
                        </GridItem>
                    </Grid>
                </Container>
            </Fade>
        </>
    );
};
