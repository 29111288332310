import { Select, chakra } from "@chakra-ui/react";

export const CustomSelect = chakra(Select, {
  baseStyle: {
    color: "darkPrimary",
    borderColor: "darkPrimary",
    minW: "135px",
    w: "100%",
    h: "35px",
    borderRadius: "10px",
    alignSelf: "flex-end",
  },
});
