import { SmallCloseIcon } from "@chakra-ui/icons";
import { Tag, TagLabel, TagProps } from "@chakra-ui/react";
import { FC } from "react";

export const MobileCloseTag: FC<TagProps> = ({ ...rest }) => (
  <Tag
    size="sm"
    minWidth="1rem"
    minHeight="1rem"
    borderRadius="full"
    variant="solid"
    backgroundColor="darkRed"
    position="relative"
    {...rest}
  >
    <TagLabel position="absolute" left="0.5px">
      <SmallCloseIcon fontSize="15px" />
    </TagLabel>
  </Tag>
);
