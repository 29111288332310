import { useMutation } from "react-query";
import { putJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

const putUserChangeData = async (data: any) =>
  putJSON(endpoints.user.data, data);

export const useUserChangeData = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isSuccess, isError, reset, status } = useMutation(
    putUserChangeData,
    {
      onSuccess: () => {},
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => {},
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    error: errors,
    status,
    userChangeData: mutate,
    resetForm: reset,
  };
};
