import { env } from "../../../env";
import { ICustomedButton } from "../CustomedButton";

export const initialButtonValue: ICustomedButton = {
    label: "Start",
    centered: true,
    height: "50px",
  };

export class BannerClass {
  title = "Abonent";
  details = [
    {
      title:  (env.REACT_APP_USE_USER_CODE === 'true') ? "Kod Klienta" : "Nr Klienta",
      data: "123-456-7890",
      textSize: "20px",
      color: "lightPrimary",
    },
  ];
  button;
  constructor(detailsData: string, title?: string, subTitle?: string, button?: boolean) {
    this.title = title || this.title;
    this.details[0].title = subTitle || this.details[0].title;
    this.details[0].data = detailsData;
    this.button = button ? initialButtonValue : undefined;
  }
}
