import { useMutation, useQueryClient } from "react-query";
import { axiosInstance, extendedPostJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useContext, useState } from "react";
import { LoginStateContext } from "../shared/components/LoginState";

const loginUser = (data: any) => extendedPostJSON(endpoints.auth, data);

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(LoginStateContext);
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isError } = useMutation(loginUser, {
    onSuccess: ({ data }) => {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data}`;
      dispatch({ type: "LOGGED_IN", payload: { token: data } });
    },
    onError: (error: any) => {
      setErrors(Object.values(error?.data.errors || {}));
      dispatch({ type: "NOT_LOGGED" });
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return {
    isError,
    errors,
    isLoading,
    loginUser: mutate,
  };
};
