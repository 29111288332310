import { FC } from "react";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from "@chakra-ui/react";

interface IFormSuccess {
  title?: string;
  text?: string;
}

export const FormSuccess: FC<IFormSuccess & AlertProps> = ({
  title,
  text,
  ...rest
}) => (
  <Alert
    status="success"
    variant="subtle"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    minHeight="200px"
    borderRadius="8px"
    {...rest}
  >
    <AlertIcon boxSize="40px" mr={0} />
    <AlertTitle mt={4} mb={1} fontSize="lg">
      {title || "Formularz został wysłany"}
    </AlertTitle>
    <AlertDescription maxWidth="sm">
      {text ||
        "Dziękujemy za wysłanie zgłoszenia. Nasz zespół zajmie się nim tak szybko, jak będzie to możliwe."}
    </AlertDescription>
  </Alert>
);
