import { Container } from "@chakra-ui/react";
import { FC } from "react";
import { useTelevisionList } from "../../hooks/useTelevisionList";
import { useUserData } from "../../hooks/useUserData";
import {
  Banner,
  Card,
  CustomedSpinner,
  PageHeader,
} from "../../shared/components";
import { BannerClass } from "../../shared/components/Banner/utils";
import { TelevisionTable } from "../../shared/components/Tables";

import bannerImage from "../../img/bannerTv/telew_top.jpg";
import { env } from "../../env";

export const Television: FC = () => {
  const { data, isLoading } = useTelevisionList();
  const userData = useUserData();

  const bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno);

  if (isLoading) {
    return <CustomedSpinner />;
  }

  return (
    <>
      <Banner bannerImage={bannerImage} {...bannerDetails} />
      <Container maxWidth={"container.xl"} flexDir={"column"}>
        <PageHeader title="Telewizja" onlyMobile />
        <Card title="Konta IPTV">
          <TelevisionTable packages={data}></TelevisionTable>
        </Card>
      </Container>
    </>
  );
};
