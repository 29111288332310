export const LocalStorageService = () => {
    const setToken = (token: string, suffix: string = '') => {
        localStorage.setItem("_ebok" + (suffix ? '_' + suffix : ''), token);
    };

    const setRegulation = (regulationNumber: string) => {
        localStorage.setItem("_regulation", regulationNumber);
    };

    const getToken = (suffix: string = '') => {
        return localStorage.getItem("_ebok" + (suffix ? '_' + suffix : ''));
    };

    const getRegulation = () => {
        return localStorage.getItem("_regulation");
    };

    const clearToken = (suffix: string = '') => {
        localStorage.removeItem("_ebok" + (suffix ? '_' + suffix : ''));
    };

    return {setToken, getToken, clearToken, setRegulation, getRegulation};
};
