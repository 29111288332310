import { chakra, MenuItem } from "@chakra-ui/react";

export const CustomMenuItem = chakra(MenuItem, {
  baseStyle: {
    height: "24px",
    fontSize: "16px",
    color: "black",
    _hover: {
      color: "lightPrimary",
      background: "transparent"
    },
    _active: {
      color: "lightPrimary",
      background: "transparent"
    },
    _focus: {
      color: "lightPrimary",
      background: "transparent"
    }
  },
});
