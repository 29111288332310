import { FC } from "react";
import { Table, Tr, Th, Td, Text, Flex } from "@chakra-ui/react";
import {
  BuyPackageModal,
  CustomedTableBody,
  CustomedTableHead,
  MobileTableBody,
  StatusBadge,
} from "../..";

export interface IDetailedPackage {
  name: string;
  description: string;
  device: string;
  type: string;
  amount: string;
  vat: string;
  status: number;
  selfService: boolean;
  id: string;
  packageid: string;
}

export interface IPackagesTable {
  packages: IDetailedPackage[];
  iptvAccountId?: string;
}

export const PackagesTable: FC<IPackagesTable> = ({
  packages,
  iptvAccountId,
}) => {
  return (
    <Table variant="simple">
      <CustomedTableHead>
        <Tr>
          <Th>Nazwa</Th>
          <Th>Typ</Th>
          <Th>Cena</Th>
          <Th>VAT</Th>
          <Th>Status</Th>
        </Tr>
      </CustomedTableHead>

      <CustomedTableBody>
        {packages.length ? (
          packages.map(
            ({
              id,
              packageid,
              name,
              device,
              type,
              amount,
              vat,
              status,
              selfService,
            }) => (
              <Tr key={id}>
                <Td>
                  <Text fontSize="14px" fontWeight="600">
                    {name}
                  </Text>
                  <Text fontSize="14px">{device}</Text>
                </Td>
                <Td>
                  <Text fontSize="14px">{type}</Text>
                </Td>
                <Td>
                  <Text fontSize="14px">{amount} zł / mc</Text>
                </Td>
                <Td>
                  <Text>{vat} %</Text>
                </Td>
                <Td>
                  {status ? (
                    <StatusBadge status="active" label="Aktywna" />
                  ) : selfService ? (
                    <BuyPackageModal
                      name={name}
                      cost={amount}
                      package_id={packageid}
                      iptvAccountId={iptvAccountId || "0"}
                    />
                  ) : (
                    <StatusBadge
                      status="inactive"
                      label="Niedostępne w eBOK"
                      width="140px"
                    />
                  )}
                </Td>
              </Tr>
            )
          )
        ) : (
          <Tr>
            <Td>Brak danych</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </CustomedTableBody>

      <MobileTableBody>
        {packages.length ? (
          packages.map(({ id, name, amount }) => (
            <Tr key={id + "mobile"}>
              <Td>
                <Flex justifyContent={"space-between"}>
                  <Text fontSize="17px" fontWeight={600}>
                    {name}
                  </Text>
                  <Text fontSize="17px" fontWeight={600}>
                    {amount} zł
                  </Text>
                </Flex>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>Brak danych</Td>
          </Tr>
        )}
      </MobileTableBody>
    </Table>
  );
};
