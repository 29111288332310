import { Container, Flex, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { CustomedButton, ICustomedButton } from "../CustomedButton";
import { CustomBannerImage, CustomBox, CustomHeader } from "./chakra";

import defaultBannerImage from "../../../img/bannerDashboard/digital.png";
import { IDetail } from "../Detail";

export interface IBanner {
  title: string;
  details?: IDetail[];
  button?: ICustomedButton;
  bannerImage?: string;
  extraContent?: ReactNode;
}

export const Banner: FC<IBanner> = ({
  title,
  details,
  button,
  bannerImage,
  extraContent,
}) => (
  <CustomBox mb="30px" display={["none", "none", "flex", "flex"]}>
    <Container maxWidth={"container.xl"} p="20px" h="full" position="relative">
      <Flex flexDir="row" justify="space-between" h="full">
        <Flex flexDir="column" zIndex={2}>
          <CustomHeader>{title}</CustomHeader>
          <Flex flexDir="row">
            {details &&
              details.map(({ title, data, color, textSize }) => (
                <Flex key={title} flexDir="column" mr="100px">
                  <Text
                    fontSize="14px"
                    color="lighterGray"
                    textTransform="uppercase"
                  >
                    {title}
                  </Text>
                  <Text fontSize={textSize} color={color}>
                    {data}
                  </Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
        {button && <CustomedButton {...button} />}
        {extraContent}
      </Flex>
      <CustomBannerImage src={bannerImage || defaultBannerImage} />
    </Container>
  </CustomBox>
);
