import { Box, TextareaProps, Text } from "@chakra-ui/react";
import { FC } from "react";
import { RegisterOptions } from "react-hook-form";
import { CustomTextArea } from "./chakra";

export interface ICustomedTextArea {
  label?: string;
  extraLabel?: string;
}

export const CustomedTextArea: FC<
  ICustomedTextArea & TextareaProps & RegisterOptions
> = ({ label, extraLabel, margin, ...rest }) => (
  <Box w="100%" margin={margin || "0 15px 0 0 "}>
    {label && (
      <Text color="basicBlack" fontSize="14px" fontWeight={600} mb="3px">
        {label} {extraLabel && `(${extraLabel})`}
      </Text>
    )}
    <CustomTextArea {...rest} />
  </Box>
);
