import { Box, Image, chakra } from "@chakra-ui/react";

export const CustomBox = chakra(Box, {
  baseStyle: {
    height: "full",
    width: "full",
    padding: "15px 20px",
    borderWidth: "0",
    borderRadius: "lg",
    backgroundColor: "white",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.1)",
  },
});

export const CustomHeader = chakra("h2", {
  baseStyle: {
    color: "darkPrimary",
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "0",
    marginBottom: "10px",
  },
});

export const CustomCardImage = chakra(Image, {
  baseStyle: {
    objectFit: "cover",
    height: "full",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    zIndex: 1,
  },
});
