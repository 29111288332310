import { useMutation, useQuery, useQueryClient } from "react-query";
import { extendedPostJSON, getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getUserDataProcess = async () => getJSON(endpoints.user.dataprocess);

export const useGetUserDataProcess = () => {
  const { data, isLoading, error } = useQuery(
    "getUserDataProcess",
    getUserDataProcess,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const postUserDataProcessForm = (data: any) =>
  extendedPostJSON(endpoints.user.dataprocess, data);

export const useUserDataProcessForm = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError, reset } = useMutation(
    postUserDataProcessForm,
    {
      onSuccess: () => {},
      onError: () => {},
      onSettled: () => {
        queryClient.invalidateQueries("contact");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    userDataProcessForm: mutate,
    resetForm: reset,
  };
};
