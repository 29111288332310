import { FC, useContext } from "react";
import { Container, Flex, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { navItems } from "../../helpers/dataCategories/Navigation";

import { CustomHeader } from "./chakra";
import { CustomLogo } from "../Header/chakra";
import logoImg from "../../../img/logo/logo.svg";
import { useNavigate } from "react-router-dom";
import {
  ChangePasswordModal,
  ChangeUserDataModal,
  ContactFormModal,
  CustomedMobileNavItem,
  MarketingAgreementsModal,
} from "..";
import {
  FaFileImage,
  FaIdCard,
  FaLock,
  FaQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import { useQueryClient } from "react-query";
import { LoginStateContext } from "../LoginState";
import { useFirm } from "../../../hooks/useFirm";
import { deleteJSON } from "../../../api/http";
import { endpoints } from "../../../api/endpoints";
interface IMobileNavigation {
  display: "none" | "flex";
  setDisplay: (value: "none" | "flex") => void;
}

const deleteLogout = async () => deleteJSON(endpoints.auth);

export const MobileNavigation: FC<IMobileNavigation> = ({
  display,
  setDisplay,
}) => {
  const firm = useFirm();
  const navigate = useNavigate();
  const { dispatch } = useContext(LoginStateContext);
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.invalidateQueries();
    deleteLogout();
    dispatch({ type: "NOT_LOGGED" });
  };

  const handleClick = (href: string) => {
    navigate(href, { replace: true });
    setDisplay("none");
  };

  const { url_eboklogo } = firm.data;

  return (
    <Flex
      w="100vw"
      h="100vh"
      bg="gray.50"
      pos="fixed"
      zIndex={20}
      top={0}
      left={0}
      overflowY="auto"
      flexDir="column"
      display={[display, display, "none", "none"]}
      padding={["15px 0"]}
    >
      <Container margin="0px" width="100%" maxW="auto">
        <Flex
          flexDir="row"
          justify="space-between"
          align="center"
          mb={4}
          height="60px"
        >
          <CustomLogo
            src={url_eboklogo || logoImg}
            m={0}
            onClick={() => {
              navigate("/");
            }}
          />
          <IconButton
            aria-label="Close Menu"
            size="lg"
            icon={<CloseIcon />}
            mr="5px"
            onClick={() => {
              setDisplay("none");
            }}
          />
        </Flex>
        <Flex flexDir="column" align="center" pl={4}>
          <CustomHeader mb={4}>Usługi</CustomHeader>
          {navItems.map(({ icon, href, label }) => (
            <Flex
              to={href}
              flexDir="row"
              justify="flex-start"
              align="center"
              w="full"
              mb={4}
              key={label}
              onClick={() => {
                handleClick(href as string);
              }}
            >
              <Text fontSize="20px" color="lightPrimary">
                {icon}
              </Text>
              <Text ml="20px" fontSize="20px" color={"gray.500"}>
                {label}
              </Text>
            </Flex>
          ))}

          <CustomHeader my={4}>Ustawienia</CustomHeader>

          <ChangeUserDataModal
            button={
              <CustomedMobileNavItem
                label="Edytuj profil"
                icon={<FaIdCard />}
              />
            }
          />
          <ChangePasswordModal
            button={
              <CustomedMobileNavItem label="Zmień hasło" icon={<FaLock />} />
            }
          />
          <MarketingAgreementsModal
            button={
              <CustomedMobileNavItem
                label="Twoje zgody"
                icon={<FaFileImage />}
              />
            }
          />

          <ContactFormModal
            button={
              <CustomedMobileNavItem
                label="Formularz kontaktowy"
                icon={<FaQuestionCircle />}
              />
            }
          />

          <CustomedMobileNavItem
            onClick={handleLogout}
            label="Wyloguj"
            icon={<FaSignOutAlt style={{ color: "#262F47" }} />}
          />
        </Flex>
      </Container>
    </Flex>
  );
};
