import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface ICustomedMobileNavItem {
  label: string;
  icon: ReactNode;
}

export const CustomedMobileNavItem: FC<ICustomedMobileNavItem & FlexProps> = ({
  icon,
  label,
  ...rest
}) => (
  <Flex
    flexDir="row"
    justify="flex-start"
    align="center"
    w="full"
    mb={4}
    {...rest}
  >
    <Text fontSize="20px" color="lightPrimary">
      {icon}
    </Text>
    <Text ml="20px" fontSize="20px" color={"gray.500"}>
      {label}
    </Text>
  </Flex>
);
