import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getUserBalanceData = async () => getJSON(endpoints.user.balance);

export const useUserBalance = () => {
  const { data, isLoading, error } = useQuery(
    "userBalance",
    getUserBalanceData,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data.value,
    error,
  };
};
