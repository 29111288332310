import {Flex, Spinner} from "@chakra-ui/react";
import {FC} from "react";

export const CustomedSpinner: FC = () => (
    <Flex h="100%" justifyContent="center" alignContent="center" mt="15%">
        <Spinner
            thickness="4px"
            speed="0.8s"
            emptyColor="gray.200"
            color="lightPrimary"
            size="xl"
        />
    </Flex>
);
