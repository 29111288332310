import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChangeEvent,
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  CustomedButton,
  CustomedSelect,
  CustomedTextArea,
  FormSuccess,
} from "../..";
import {
  useContactForm,
  useContactFormDict,
} from "../../../../hooks/useContact";

export interface IContactFormModal {
  button?: ReactElement;
  action?: string;
  type?: string;
}

export const ContactFormModal: FC<IContactFormModal> = ({ button, action }) => {
  const subjectDict = useContactFormDict();
  const {
    isLoading,
    isSuccess,
    isError,
    errors,
    contactForm,
    resetContactForm,
  } = useContactForm();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState({
    subject: "0",
    body: "",
  });

  const handleInputChange = (key: string, value: string) => {
    setInputs((oldState) => {
      const newState = { ...oldState, [key]: value };
      return newState;
    });
  };

  const onSubmit = (values: any) => {
    if (values) {
      contactForm(values);
    }
  };

  const handleClose = () => {
    resetContactForm();
    if (!action) {
      setInputs({
        subject: "0",
        body: "",
      })
    }
    onClose();
  };

  useEffect(() => {
    setInputs((oldState) => {
      const newState = {
        ...oldState,
        subject:
          subjectDict.data ? subjectDict.data.list.find(
            (subject: any) => subject.action === action
          )?.id || "0" : '',
      };
      return newState;
    });
  }, [action, subjectDict.data ? subjectDict.data.list : '']);

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: onOpen })
      ) : (
        <CustomedButton
          centered={true}
          fontSize="14px"
          height="35px"
          label="Kontakt"
          onClick={onOpen}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay onClick={handleClose} />
        <ModalContent
          minW={["100%", "100%", "500px"]}
          minH={["100%", "100%", "initial"]}
          m={["0px", "0px", "60px 0px"]}
          borderRadius={["0px", "0px", "10px"]}
        >
          <ModalHeader color="darkPrimary">Formularz Kontaktowy</ModalHeader>

          <ModalCloseButton
            fontSize={["20px", "20px", "12px"]}
            m={["10px", "10px", "0px"]}
            onClick={handleClose}
          />
          <ModalBody>
            {isSuccess ? (
              <FormSuccess />
            ) : (
              <>
                {isError && (
                  <Alert status="error" borderRadius="8px" mb="10px">
                    <AlertIcon />
                    <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                    <AlertDescription mr="15px">
                      {errors.join(" ")}
                    </AlertDescription>
                    <CloseButton
                      position="absolute"
                      right="8px"
                      top="8px"
                      onClick={resetContactForm}
                    />
                  </Alert>
                )}
                <CustomedSelect
                  label="Temat"
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    handleInputChange("subject", event.target.value);
                  }}
                  value={inputs.subject}
                  placeholder="Wybierz temat"
                  isDisabled={!!action}
                  margin="0 0 10px 0"
                >
                  {subjectDict.data ? subjectDict.data.list.map(
                    ({ id, topic }: { id: string; topic: string }) => (
                      <option key={id} value={id}>
                        {topic}
                      </option>
                    )
                  ) : ''}
                </CustomedSelect>
                <CustomedTextArea
                  label="Treść wiadomości"
                  placeholder="Wpisz treść wiadomości..."
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    handleInputChange("body", event.target.value);
                  }}
                  rows={10}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex
              flexDir={["column-reverse", "column-reverse", "row"]}
              justifyContent="center"
              w="100%"
            >
              {!isSuccess && (
                <Button
                  backgroundColor="lightPrimary"
                  _hover={{ backgroundColor: "lighterPrimary" }}
                  color="white"
                  p="10px 30px"
                  size="sm"
                  h="35px"
                  onClick={() => onSubmit(inputs)}
                  isLoading={isLoading}
                  isDisabled={
                    isSuccess || inputs.subject === "0" || !inputs.body.trim().length
                  }
                >
                  Wyślij
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
