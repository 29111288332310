// useContractDeclarationForm
import { useMutation, useQueryClient } from "react-query";
import { extendedPostJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

const postContractDeclarationForm = (data: any) =>
  extendedPostJSON(endpoints.contracts.declaration, data);

export const useContractDeclarationForm = () => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isSuccess, isError, data, reset } = useMutation(
    postContractDeclarationForm,
    {
      onSuccess: () => {},
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => {
        queryClient.invalidateQueries("contact");
      },
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    errors,
    contractForm: mutate,
    resetForm: reset,
  };
};
