import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { formatTime, formatTransfer } from "../shared/utils";
import { IQueryOptions } from "../core/types";
import { stringify } from "querystring";

export interface IBESummary {
  duration_total: number;
  duration_free: number;
  datatransfer_total: number;
  datatransfer_free: number;
  msg_total: number;
  msg_free: number;
  total_count: number;
  total_netto: string;
  total_vat: string;
  total_brutto: string;
}

export const restructureSummaryData = (summary: IBESummary) => {
  if (summary) {
    const {
      duration_total,
      duration_free,
      datatransfer_total,
      datatransfer_free,
    } = summary;
    return {
      ...summary,
      duration_total: formatTime(duration_total),
      duration_free: formatTime(duration_free),
      datatransfer_total: formatTransfer(datatransfer_total),
      datatransfer_free: formatTransfer(datatransfer_free),
    };
  }
  return {
    duration_total: "00:00 min",
    duration_free: "00:00 min",
    datatransfer_total: 0,
    datatransfer_free: 0,
    msg_total: 0,
    msg_free: 0,
    total_netto: "0.00",
    total_vat: "0.00",
    total_brutto: "0.00",
  };
};

const getMobileBillingSummary = async (stringParams: string) =>
  getJSON(
    [endpoints.mobile.summary, stringParams && "?", stringParams].join("")
  );

export const useMobileBillingSummary = ({ filters }: IQueryOptions) => {
  let urlParams = "";
  if (filters) urlParams += stringify(filters);

  const { data, isLoading, error } = useQuery(
    "mobilebillingSummary" + urlParams,
    () => getMobileBillingSummary(urlParams),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureSummaryData(data?.data),
    error,
  };
};
