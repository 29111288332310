import { Flex, SelectProps, Text } from "@chakra-ui/react";
import { FC } from "react";
import { RegisterOptions } from "react-hook-form";
import { CustomSelect } from "./chakra";

export interface ICustomedSelect {
  label?: string;
  placeholder?: string;
}

export const CustomedSelect: FC<
  ICustomedSelect & SelectProps & RegisterOptions
> = ({ label, margin, placeholder, onChange, children, ...rest }) => (
  <Flex flexDir="column" margin={margin || "0 15px 0 0 "}>
    {label && (
      <Text color="basicBlack" fontSize="14px" fontWeight={600} mb="3px">
        {label}
      </Text>
    )}
    <CustomSelect
      placeholder={placeholder || "Wszystkie"}
      onChange={onChange}
      {...rest}
    >
      {children}
    </CustomSelect>
  </Flex>
);
