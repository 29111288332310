import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

export interface IBEInternetDevice {
  id: string;
  ipaddr: string;
  ipaddr_string: string;
  userid: string;
  serverid: string;
  networkid: string;
  name: string;
  mac: string;
  upceil: string;
  downceil: string;
  access: string;
}

export interface IBEInternet {
  list: IBEInternetDevice[];
  total: number;
}

export const restructureInternet = (internet: IBEInternet) =>
  internet
    ? internet.list.map(({ ipaddr_string, mac, upceil, downceil, id }) => ({
        ipAddress: ipaddr_string,
        macAddress: mac,
        download: downceil,
        upload: upceil,
        id: id,
      }))
    : [];

const getInternetList = async () => getJSON(endpoints.internet.list);

export const useInternetList = () => {
  const { data, isLoading, error } = useQuery("internetList", getInternetList, {
    staleTime: Infinity,
  });

  return {
    isLoading,
    data: restructureInternet(data?.data),
    error,
  };
};
