export const endpoints = {
    annexes: {
        details: "/api/annex",
        list: "/api/annex/shortlist",
    },
    auth: "/api/ebok/auth",
    contact: {
        dict: {
            error: "/api/mail_boktopics/action/apperror",
            extendContract: "/api/mail_boktopics/action/contract",
            subjects: "/api/mail_boktopics/",
        },
        form: "/api/ebok/contact",
    },
    contracts: {
        declaration: "/api/ebok/declarations",
        details: "/api/contracts",
        dict: {
            contractEndType: "/api/ebok/declarations/values/type",
            declaration: "/api/ebok/declarations/prepare",
            declarationTypes: "/api/contracts/{id}/declarationtypes",
        },
        list: "/api/contracts/shortlist",
        withAnnexes: "/api/contracts/with_annexes",
    },
    globalDamages: "/api/globaldamages/active",
    internet: {
        details: "/api/nodes/",
        list: "/api/nodes/shortlist",
    },
    invoices: {
        dict: {
            settled: "/api/invoicesheader/values/settled/",
            year: "/api/invoicesheader/values/TrN_DataDok_year/",
        },
        download: "/api/invoicesheader/{id}/download",
        last: "/api/invoicesheader/last",
        list: "/api/invoicesheader/shortlist"
    },
    mmsLogin: "/api/ebok/auth_mms/{id}",
    mobile: {
        billing: "/api/mvno_billing/shortlist",
        dict: {
            categories: "/api/mvno_traffic_categories/shortlist",
            month: "/api/mvno_billing/values/calldate_month",
            type: "/api/mvno_traffic_types/shortlist",
        },
        download: "/api/mvno_billing/download",
        phones: "/api/mobiles/shortlist",
        summary: "/api/mvno_billing/summary",
    },
    parentalControlDictionary: "/api/nodes/values/workhour_type",
    payments: {
        download: "/api/internal_invoices/download",
        list: "/api/invoicespayments/shortlist",
    },
    regulations: "/api/ebok/regulations",
    rodo: "/api/ebok/rodo",
    services: {list: "/api/actual_services/shortlist"},
    television: {
        list: "/api/iptv_accounts/shortlist",
        packages: "/api/iptv_accounts/",
        subscriptions: "/api/iptv_orders/",
    },
    telephony: {
        billing: "/api/voip_billing/shortlist",
        categories: "/api/voip_traffic_categories/shortlist",
        phones: "/api/phones/shortlist",
        summary: "/api/voip_billing/summary",
        dict: {
            country: "/api/voip_billing/values/international",
            context: "/api/voip_billing/values/dcontext/",
            disposition: "/api/voip_billing/values/disposition/",
            month: "/api/voip_billing/values/calldate_month",
        },
        download: "api/voip_billing/download"
    },
    user: {
        balance: "/api/ebok/user/balance",
        changePassword: "/api/ebok/auth/changepassword",
        data: "/api/ebok/user",
        dataprocess: "/api/ebok/user/dataprocess",
        firm: "/api/firms/active",
        parentalControl: "/api/nodes",
        payment: {
            channels: "/api/ebok/payments/channels",
            details: "/api/ebok/payments/prepare",
            send: "/api/ebok/payments",
        },
    },
};
