import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { cloneElement, FC, ReactElement, useEffect, useState } from "react";
import { CustomedButton } from "../..";
import {
  useGetUserDataProcess,
  useUserDataProcessForm,
} from "../../../../hooks/useUserDataProcess";

export interface IMarketingAgreementsModal {
  button?: ReactElement;
}

interface IMarketingAgreementData {
  cdpid: string;
  name: string;
  description: string;
  dpvalue: string;
}

export const MarketingAgreementsModal: FC<IMarketingAgreementsModal> = ({
  button,
}) => {
  const { data } = useGetUserDataProcess();
  const { isLoading, isSuccess, userDataProcessForm, resetForm } =
    useUserDataProcessForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState<IMarketingAgreementData[]>([]);

  const handleSwitchChange = (id: string) => {
    setInputs((oldState) => {
      const newState = oldState;
      const index = newState.findIndex((element) => element.cdpid === id);
      if (newState[index].dpvalue === "0" || newState[index].dpvalue === null) {
        newState[index].dpvalue = "1";
      } else {
        newState[index].dpvalue = "0";
      }
      return newState;
    });
  };

  const onSubmit = (values: IMarketingAgreementData[]) => {
    if (values) {
      userDataProcessForm(
        values.map(({ cdpid, dpvalue }) => ({ cdpid, dpvalue: dpvalue || "0" }))
      );
    }
  };

  const onFormClose = () => {
    if (data) {
      setInputs(data);
    }
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (data) {
      setInputs([...data]);
    }
  }, [data, setInputs]);

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: onOpen })
      ) : (
        <CustomedButton
          centered={true}
          fontSize="14px"
          height="35px"
          label="Twoje zgody"
          onClick={onOpen}
        />
      )}

      <Modal isOpen={isOpen} onClose={onFormClose}>
        <ModalOverlay onClick={onFormClose} />
        <ModalContent
          minW={["100%", "100%", "500px"]}
          minH={["100%", "100%", "initial"]}
          m={["0px", "0px", "60px 0px"]}
          borderRadius={["0px", "0px", "10px"]}
        >
          <ModalHeader color="darkPrimary">Twoje zgody</ModalHeader>

          <ModalCloseButton
            fontSize={["20px", "20px", "12px"]}
            m={["10px", "10px", "0px"]}
          />
          <ModalBody>
            {isSuccess && (
              <Alert status="success" borderRadius="8px" mb="10px">
                <AlertIcon />
                Pomyślnie zmieniono dane
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={resetForm}
                />
              </Alert>
            )}
            {inputs.map(({ cdpid, description, dpvalue }) => (
              <FormControl
                key={cdpid}
                display="flex"
                alignItems="center"
                mb="10px"
              >
                <Switch
                  id={"cdpid" + cdpid}
                  defaultChecked={!!Number(dpvalue)}
                  onChange={() => {
                    handleSwitchChange(cdpid);
                  }}
                />
                <FormLabel htmlFor={"cdpid" + cdpid} ml="10px">
                  {description}
                </FormLabel>
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Flex
              justifyContent="center"
              w="100%"
              flexDir={["column-reverse", "column-reverse", "row"]}
            >
              <Button
                backgroundColor="lightPrimary"
                _hover={{ backgroundColor: "lighterPrimary" }}
                color="white"
                p="10px 30px"
                size="sm"
                h="35px"
                onClick={() => onSubmit(inputs)}
                isLoading={isLoading}
              >
                Zapisz
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
