import { IQueryOptions } from "./../core/types/queries";
import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";

const getMobileBillingMonthDict = async () =>
  getJSON(endpoints.mobile.dict.month);

export const useMobileBillingMonthDict = () => {
  const { data, isLoading, error } = useQuery(
    "MobileBillingMonthDict",
    getMobileBillingMonthDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: data?.data,
    error,
  };
};

const getMobileBillingCategoryDict = async (id?: string) => {
  return getJSON(
    [endpoints.mobile.dict.categories, id && "?traffic_types_id=" + id].join("")
  );
};

export const useMobileBillingCategoryDict = ({ filters }: IQueryOptions) => {
  const { data, isLoading, error } = useQuery(
    "MobileBillingCategoryDict" + filters?.traffic_types_id,
    () => getMobileBillingCategoryDict(filters?.traffic_types_id as string),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureBillingType(data?.data),
    error,
  };
};

interface IBillingType {
  id: string;
  type: string;
  description: string;
}

interface ITypeData {
  list: IBillingType[];
  total: number;
}

const restructureBillingType = (data: ITypeData) => {
  const typeRecord: { [k: string]: string } = {};
  data &&
    data.list &&
    data.list.forEach(({ id, description }) => (typeRecord[id] = description));
  return typeRecord;
};

const getMobileBillingTypeDict = async () =>
  getJSON(endpoints.mobile.dict.type);

export const useMobileBillingTypeDict = () => {
  const { data, isLoading, error } = useQuery(
    "MobileBillingTypeDict",
    getMobileBillingTypeDict,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureBillingType(data?.data),
    error,
  };
};
