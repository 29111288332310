import { TabList, Tabs } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

import { navItems } from "../../helpers/dataCategories/Navigation";
import { FC, useEffect, useState } from "react";
import { CustomTab } from "./chakra";
import { CustomIcon } from "../CustomIcon";
import { useFirm } from "../../../hooks/useFirm";

export const SubNavigation: FC = () => {
  const { ebok_rights } = useFirm().data;

  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const itemId = navItems.find(
      ({ href }) => href === "/" + location.pathname.split("/")[1]
    )?.id;

    if (ebok_rights) {
      const index = (ebok_rights as string[]).findIndex(
        (item) => item === itemId
      );
      setTabIndex(index + 1);
    }
  }, [location, ebok_rights]);

  return (
    <Tabs
      display={["none", "none", "flex", "flex"]}
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabList w="full" style={{ justifyContent: "center" }}>
        <CustomTab onClick={() => navigate("/")}>
          <CustomIcon>
            <FaHome />
          </CustomIcon>
          Panel
        </CustomTab>
        {navItems
          .filter(({ id }) => ebok_rights?.includes(id))
          .map((navItem) => (
            <CustomTab
              key={navItem.label}
              onClick={() => navigate(`${navItem.href}`)}
            >
              <CustomIcon> {navItem.icon} </CustomIcon> {navItem.label}
            </CustomTab>
          ))}
      </TabList>
    </Tabs>
  );
};
