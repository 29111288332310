import { CheckIcon } from "@chakra-ui/icons";
import { Tag, TagLabel, TagProps } from "@chakra-ui/react";
import { FC } from "react";

export const MobileCheckTag: FC<TagProps> = ({ ...rest }) => (
  <Tag
    size="sm"
    minWidth="1rem"
    minHeight="1rem"
    borderRadius="full"
    variant="solid"
    backgroundColor="darkGreen"
    position="relative"
    {...rest}
  >
    <TagLabel position="absolute" left="3px">
      <CheckIcon fontSize="10px" />
    </TagLabel>
  </Tag>
);
