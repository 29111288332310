import {FC} from "react";
import {DangerBadge, SuccessBadge} from "./chakra";
import {
	Text
} from "@chakra-ui/react";

export interface IStatusBadge {
	label: string;
	status: "active" | "inactive";
	width?: string;
	style?: object;
}

export const StatusBadge: FC<IStatusBadge> = ({label, status, width, style}) => (
	<>
		{status === "active" && (
			<SuccessBadge width={width || "90px"}>
				<Text style={style}>{label}</Text>
			</SuccessBadge>
		)}
		{status === "inactive" && (
			<DangerBadge width={width || "90px"}>
				<Text style={style}>{label}</Text>
			</DangerBadge>
		)}
	</>
);
