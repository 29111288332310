import {useQuery} from "react-query";
import {endpoints} from "../api/endpoints";
import {getJSON} from "../api/http";

const getDeclarationTypes = async (id: string) =>
	getJSON(endpoints.contracts.dict.declarationTypes.replaceAll("{id}", id));

export const useDeclarationTypes = (id: string) => {
	const {data, isLoading, error} = useQuery(
		"declarationTypes" + id,
		() => getDeclarationTypes(id),
		{
			staleTime: Infinity,
		}
	);

	return {
		isLoading,
		data: data?.data || {},
		error,
	};
};
