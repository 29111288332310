import { Box, InputGroup, InputProps, Text, TextProps } from "@chakra-ui/react";
import { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { CustomInput } from "./chakra";

export interface ICustomedInput {
  label?: string;
  extraLabel?: string;
  labelStyle?: TextProps["style"];
  register?: UseFormRegisterReturn;
  error?: string;
}

export const CustomedInput: FC<ICustomedInput & InputProps> = ({
  label,
  extraLabel,
  labelStyle,
  margin,
  type,
  register,
  error,
  ...rest
}) => (
  <Box w="100%" margin={margin || "0 15px 0 0 "}>
    {label && (
      <Text color="black" fontSize="14px" mb="3px" style={labelStyle}>
        {label} {extraLabel && `(${extraLabel})`}
      </Text>
    )}
    <InputGroup>
      <CustomInput type={type || "text"} {...register} {...rest} />
    </InputGroup>
    {error && (
      <Text color="red" fontSize="12px" ml="3px">
        {error}
      </Text>
    )}
  </Box>
);
