import { TableHeadProps, Thead } from "@chakra-ui/react";
import { FC } from "react";

const noMobileDisplay = ["none", "none"];

export const CustomedTableHead: FC<TableHeadProps> = ({
  children,
  ...rest
}) => (
  <Thead {...rest} display={[...noMobileDisplay, "table-header-group"]}>
    {children}
  </Thead>
);
