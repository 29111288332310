import { FC, useEffect, useState } from "react";
import {
  Box,
  Table,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
import { CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";
import { useInvoicesList } from "../../../../hooks/useInvoicesList";
import { IBEInovices, IPagination } from "../../../../core/types";
import { restructureDate } from "../../../utils";
import { Pagination } from "../../Pagination";

export const restructureInvoices = (invoices: IBEInovices) =>
  invoices
    ? invoices.list.map(
      ({
        id,
        TrN_NumerPelny,
        TrN_DataWys_UTS,
        TrN_RazemBrutto,
        settled_left,
      }) => ({
        id,
        name: TrN_NumerPelny,
        date: restructureDate(TrN_DataWys_UTS),
        amount: settled_left,
      })
    )
    : [];

const DEFAULT_PAGINATION: IPagination = {
  _limit: 10,
  _page: 1,
  _order: "desc",
};

interface IInvoice {
  id: string;
  name: string;
  cost: string;
  checked: boolean;
}

interface IInvoicesNotSettledTable {
  blocked: number;
  checked: string[];
  defaultSelected: boolean;
  handleCheck: (invoice: IInvoice) => void;
}

export const InvoicesNotSettledTable: FC<IInvoicesNotSettledTable> = ({
  blocked,
  checked,
  defaultSelected,
  handleCheck,
}) => {
  const [pagination, setPagination] = useState<IPagination>(DEFAULT_PAGINATION);
  const rawInvoices = useInvoicesList({
    // pagination,
    filters: { settled: "0" },
  });
  const invoices = restructureInvoices(rawInvoices.data);

  const handlePageClick = (page: number) => {
    setPagination((oldState) => {
      const newState = { ...oldState, _page: page };
      return newState;
    });
  };

  useEffect(() => {
    if (invoices.length && (blocked > 1 || defaultSelected)) {
      invoices.map(({ id, name, date, amount }) => {
        handleCheck({ id, name, cost: amount, checked: true });
        return null;
      })
    }
  }, [invoices.length]);

  if (rawInvoices.isLoading) {
    return (
      <Flex justifyContent="center" alignContent="center">
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="lightPrimary"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      <Table variant="simple">
        <Box display={["block", "block", "block"]} boxShadow={["md", "md", "md"]} borderLeftRadius="10px" maxH={'400px'} overflowY='auto' >
          <CustomedTableHead>
            <Tr>
              <Th width="50px">Wybierz</Th>
              <Th>Numer dokumentu</Th>
              <Th>Data wystawienia</Th>
              <Th>Kwota brutto</Th>
            </Tr>
          </CustomedTableHead>
          <CustomedTableBody>
            {invoices.length ? (
              invoices.map(({ id, name, date, amount }) => (
                <Tr key={id + name}>
                  <Td width="50px">
                    <Checkbox
                      isChecked={checked.includes(id)}
                      onChange={(e) => handleCheck({ id, name, cost: amount, checked: false })}
                      isDisabled={blocked > 1}
                    />
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {name}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="basicBlack">
                      {date}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="14px" color="darkPrimary">
                      {amount} zł
                    </Text>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>Brak danych</Td>
              </Tr>
            )}
          </CustomedTableBody>

          <MobileTableBody>
            {invoices.length ? (
              invoices.map(({ id, name, date, amount }) => (
                <Tr key={name + date + "mobile"} onClick={(e) => {
                  if (blocked <= 1) {
                    handleCheck({ id, name, cost: amount, checked: false })
                  }
                }}>
                  <Td p="5px 0">
                    <Flex justifyContent={"space-between"} alignItems="center">
                      <Checkbox
                        isChecked={checked.includes(id)}
                        // onChange={(e) => handleCheck({ id, name, cost: amount, checked: false })}
                        isDisabled={blocked > 1}
                      />
                      <Box m="0 auto 0 10px">
                        <Text fontSize="14px" fontWeight={600}>
                          {name}
                        </Text>
                        <Text fontSize="14px">{date}</Text>
                      </Box>
                      <Text fontSize="16px" color="darkPrimary" fontWeight={600}>
                        {amount} zł
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td>Brak danych</Td>
              </Tr>
            )}
          </MobileTableBody>
        </Box>
      </Table>

      {/* <Pagination
        totalCount={rawInvoices?.data?.total || 0}
        pageSize={pagination._limit}
        siblingCount={1}
        currentPage={pagination._page}
        onPageChange={handlePageClick}
      /> */}
    </>
  );
};
