import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    CloseButton,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Text,
    useDisclosure,
} from "@chakra-ui/react";
import {ChangeEvent, cloneElement, FC, ReactElement, useEffect, useState} from "react";
import {CustomedButton, CustomedInput} from "../..";
import {useUserChangePassword} from "../../../../hooks/useUserChangePassword";
import {InfoIcon} from "@chakra-ui/icons";
import * as React from "react";

export interface IChangePasswordModal {
    button?: ReactElement;
    canBeClosed?: Boolean;
    description?: String;
    noButton?: Boolean;
    withOldPassword?: Boolean;
    setChangedPass?: Function;
}

interface IPasswords {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};

export const ChangePasswordModal: FC<IChangePasswordModal> = ({
                                                                  button,
                                                                  canBeClosed,
                                                                  description,
                                                                  noButton,
                                                                  setChangedPass
                                                              }) => {
    const {
        isLoading,
        isSuccess,
        isError,
        errors,
        userChangePassword,
        resetForm,
    } = useUserChangePassword(setChangedPass);

    const {isOpen, onOpen, onClose} = useDisclosure();
    const [inputs, setInputs] = useState<IPasswords>(initialState);

    const handleInputChange = (key: string, value: string) => {
        setInputs((oldState) => {
            const newState = {...oldState, [key]: value};
            return newState;
        });
    };

    const onSubmit = (values: IPasswords) => {
        if (values) {
            const t = userChangePassword({
                pass: values.oldPassword,
                new_pass: values.newPassword,
                confirm_pass: values.confirmNewPassword
            });
        }
    };

    const handleClose = () => {
        if (canBeClosed == null || canBeClosed == undefined || canBeClosed) {
            resetForm();
            onClose();
        }
    };

    useEffect(() => {
        if (noButton) {
            onOpen();
        }
    });

    return (
        <>
            {(noButton == undefined || noButton == null || !noButton) && button ? (
                cloneElement(button, {onClick: onOpen})
            ) : ((noButton == undefined || noButton == null || !noButton) &&
                <CustomedButton
                    centered={true}
                    fontSize="14px"
                    height="35px"
                    label="Zmień hasło"
                    onClick={onOpen}
                />
            )}

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay onClick={handleClose}/>
                <ModalContent
                    minW={["100%", "100%", "500px"]}
                    minH={["100%", "100%", "initial"]}
                    m={["0px", "0px", "60px 0px"]}
                    borderRadius={["0px", "0px", "10px"]}
                >
                    <ModalHeader color="darkPrimary">Zmień hasło</ModalHeader>

                    {(canBeClosed == null || canBeClosed == undefined || canBeClosed) &&
                    <ModalCloseButton
                        fontSize={["20px", "20px", "12px"]}
                        m={["10px", "10px", "0px"]}
                    />
                    }
                    <ModalBody>
                        {description && (
                            <Alert status="success" borderRadius="8px" mb="10px">
                                <InfoIcon style={{
                                    marginRight: "16px"
                                }}/>
                                {description}
                            </Alert>
                        )}
                        {isSuccess && (
                            <Alert status="success" borderRadius="8px" mb="10px">
                                <AlertIcon/>
                                Pomyślnie zmieniono hasło
                                <CloseButton
                                    position="absolute"
                                    right="8px"
                                    top="8px"
                                    onClick={resetForm}
                                />
                            </Alert>
                        )}
                        {isError && (
                            <Alert status="error" borderRadius="8px" mb="10px">
                                <AlertIcon/>
                                <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                                <AlertDescription mr={"15px"}>
                                    {errors.join(" ")}
                                </AlertDescription>
                                <CloseButton
                                    position="absolute"
                                    right="8px"
                                    top="8px"
                                    onClick={resetForm}
                                />
                            </Alert>
                        )}
                        <CustomedInput
                            label="Obecne hasło"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange("oldPassword", event.target.value);
                            }}
                            type="password"
                            margin="0px"
                            mb="10px"
                        />
                        <Flex flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row">
                            <CustomedInput
                                label="Nowe hasło"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange("newPassword", event.target.value);
                                }}
                                isInvalid={inputs.newPassword !== inputs.confirmNewPassword}
                                type="password"
                                margin={["0px", "0px", "0px 15px 0px 0px"]}
                                mb="10px"
                            />
                            <CustomedInput
                                label="Powtórz nowe hasło"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange("confirmNewPassword", event.target.value);
                                }}
                                isInvalid={inputs.newPassword !== inputs.confirmNewPassword}
                                type="password"
                                margin="0px"
                                mb="10px"
                            />
                        </Flex>
                        {inputs.newPassword && inputs.newPassword === inputs.oldPassword ? (
                            <Text color="red" fontSize="12px" ml="3px">
                                Nowe hasło nie może być takie samo jak obecne
                            </Text>
                        ) : inputs.newPassword !== inputs.confirmNewPassword ? (
                            <Text color="red" fontSize="12px" ml="3px">
                                Hasła się od siebie różnią
                            </Text>
                        ) : ''}
                    </ModalBody>
                    <ModalFooter>
                        <Flex
                            justifyContent="center"
                            w="100%"
                            flexDir={["column-reverse", "column-reverse", "row"]}
                        >
                            <Button
                                backgroundColor="lightPrimary"
                                _hover={{backgroundColor: "lighterPrimary"}}
                                color="white"
                                p="10px 30px"
                                size="sm"
                                h="35px"
                                onClick={() => onSubmit(inputs)}
                                isLoading={isLoading}
                                isDisabled={
                                    !inputs.oldPassword.length ||
                                    !inputs.newPassword.length ||
                                    !inputs.confirmNewPassword.length ||
                                    inputs.newPassword !== inputs.confirmNewPassword ||
                                    inputs.newPassword === inputs.oldPassword
                                }
                            >
                                Zapisz
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
