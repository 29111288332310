import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { cloneElement, FC, ReactElement, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomedButton, CustomedInput } from "../..";
import { useUserChangeData } from "../../../../hooks/useUserChangeData";
import { useUserData } from "../../../../hooks/useUserData";
import { setgroups } from "process";

export interface IChangeUserDataModal {
  button?: ReactElement;
}

interface IUserData {
  email?: string;
  phone1?: string;
  email2?: string;
  phone2?: string;
  email3?: string;
  phone3?: string;
  email_invoices?: string;
}

interface IEmailValues {
  primaryEmail: string;
  optionalEmail?: string;
  optionalEmail2?: string;
  invoicesEmail?: string;
}

interface IPhoneValues {
  primaryPhone: string;
  optionalPhone?: string;
  optionalPhone2?: string;
}

export const ChangeUserDataModal: FC<IChangeUserDataModal> = ({ button }) => {
  const { data, refetch } = useUserData();
  // console.log('user data: ' + JSON.stringify(data))
  const [emailValue, setEmailValue] = useState<IEmailValues>(
    {
      // primaryEmail: /* data ?  */data.email /* : 'Błąd danych' */,
      primaryEmail: '',
      // optionalEmail: data ? data.email2 : '',
      optionalEmail: '',
      // optionalEmail2: data ? data.email3 : '',
      optionalEmail2: '',
      // invoicesEmail: data ? data.email_invoices : 'Błąd danych',
      invoicesEmail: '',
    }
  )
  const [phoneValue, setPhoneValue] = useState<IPhoneValues>(
    {
      primaryPhone: '',
      optionalPhone: '',
      optionalPhone2: '',
    }
  )

  const setEmailAndPhoneData = () => {

    setEmailValue({
      primaryEmail: data?.email ? data.email : '',
      optionalEmail: data ?  data.email2 : '',
      optionalEmail2: data? data?.email3 : '',
      invoicesEmail: data ? data.email_invoices : '',
    })
    setPhoneValue(
      {
        primaryPhone: data ? data.phone1: '',
        optionalPhone: data ? data.phone2 : '',
        optionalPhone2: data ? data.phone3 : '',
      }
    )

  }

  //when data comes from API update local data
  useEffect(() => {
    if (data) {
      setEmailAndPhoneData()
    }
  }, [data])

  // useEffect(() => {
  //   console.log('emailValue: ' + JSON.stringify(emailValue))
  //   console.log('phoneValue: ' + JSON.stringify(phoneValue))
  // }, [emailValue, phoneValue])

  const { isLoading, isSuccess, isError, error, userChangeData, resetForm } =
    useUserChangeData();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<IUserData>();

  const onSubmit: SubmitHandler<IUserData> = async (data) => {
    userChangeData(
      Object.fromEntries(
        Object.entries(data)
          // .filter(([_, v]) => (_ !== 'email') && v !== "")
          // .filter(([_, v]) => v !== "")
          .filter(([_, v]) => v !== undefined)
      )
    );
    refetch();
  };

  const handleClose = () => {
    refetch()
    setEmailAndPhoneData()
    reset();
    resetForm();
    onClose();
  };

  const handleCloseError = () => {
    setEmailAndPhoneData()
    resetForm()
  }

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: onOpen })
      ) : (
        <CustomedButton
          centered={true}
          fontSize="14px"
          height="35px"
          label="Zmień hasło"
          onClick={onOpen}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay onClick={handleClose} />
        <ModalContent
          minW={["100%", "100%", "500px"]}
          minH={["100%", "100%", "initial"]}
          m={["0px", "0px", "60px 0px"]}
          borderRadius={["0px", "0px", "10px"]}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader color="darkPrimary">Edytuj profil</ModalHeader>

            <ModalCloseButton
              fontSize={["20px", "20px", "12px"]}
              m={["10px", "10px", "0px"]}
            />
            <ModalBody>
              {isSuccess && (
                <Alert status="success" borderRadius="8px" mb="10px">
                  <AlertIcon />
                  Pomyślnie zmieniono dane
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={resetForm}
                  />
                </Alert>
              )}
              {isError && (
                <Alert status="error" borderRadius="8px" mb="10px">
                  <AlertIcon />
                  <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                  <AlertDescription mr={"15px"}>
                    {error.join(" ")}
                  </AlertDescription>
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    // onClick={resetForm}
                    onClick={handleCloseError}
                  />
                </Alert>
              )}

              <Flex flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row">
                <CustomedInput
                  label="E-mail"
                  // placeholder={data?.email || 'Bład danych'}
                  // value={data?.email || 'Bład danych'}
                  value={emailValue.primaryEmail}
                  onChange={(text) => {
                    setEmailValue({ ...emailValue, primaryEmail: text.target.value })
                  }}
                  // _placeholder={{opacity: 1, color: 'black'}}
                  isInvalid={!!errors.email}
                  error={errors.email?.message}
                  type="email"
                  margin={["0px", "0px", "0px 15px 0px 0px"]}
                  mb="10px"
                  register={register("email")}
                />
                <CustomedInput
                  label="Telefon"
                  // placeholder={data?.phone1 || 'Bład danych'}
                  value={phoneValue.primaryPhone}
                  onChange={(text) => {
                    setPhoneValue({ ...phoneValue, primaryPhone: text.target.value })
                  }}
                  isInvalid={!!errors.phone1}
                  error={errors.phone1?.message}
                  type="tel"
                  margin="0px"
                  mb="10px"
                  register={register("phone1", {
                    pattern: {
                      value:
                        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                      message: "Błędny numer telefonu",
                    },
                  })}
                />
              </Flex>
              <Box mb="10px" />
              <Flex flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row">
                <CustomedInput
                  label="E-mail 2"
                  extraLabel="Opcjonalne"
                  // placeholder={data?.email2 || ''}
                  value={emailValue.optionalEmail}
                  onChange={(text) => {
                    setEmailValue({ ...emailValue, optionalEmail: text.target.value })
                  }}
                  isInvalid={!!errors.email2}
                  error={errors.email2?.message}
                  type="email"
                  margin={["0px", "0px", "0px 15px 0px 0px"]}
                  mb="10px"
                  register={register("email2")}
                />
                <CustomedInput
                  label="Telefon 2"
                  extraLabel="Opcjonalne"
                  // placeholder={data?.phone2 || ''}
                  value={phoneValue.optionalPhone}
                  onChange={(text) => {
                    setPhoneValue({ ...phoneValue, optionalPhone: text.target.value })
                  }}
                  isInvalid={!!errors.phone2}
                  error={errors.phone2?.message}
                  type="tel"
                  margin="0px"
                  mb="10px"
                  register={register("phone2", {
                    pattern: {
                      value:
                        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                      message: "Błędny numer telefonu",
                    },
                  })}
                />
              </Flex>
              <Flex flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row">
                <CustomedInput
                  label="E-mail 3"
                  extraLabel="Opcjonalne"
                  // placeholder={data?.email3 || ''}
                  value={emailValue.optionalEmail2}
                  onChange={(text) => {
                    setEmailValue({ ...emailValue, optionalEmail2: text.target.value })
                  }}
                  isInvalid={!!errors.email3}
                  error={errors.email3?.message}
                  type="email"
                  margin={["0px", "0px", "0px 15px 0px 0px"]}
                  mb="10px"
                  register={register("email3")}
                />
                <CustomedInput
                  label="Telefon 3"
                  extraLabel="Opcjonalne"
                  // placeholder={data?.phone3 || ''}
                  value={phoneValue.optionalPhone2}
                  onChange={(text) => {
                    setPhoneValue({ ...phoneValue, optionalPhone2: text.target.value })
                  }}
                  isInvalid={!!errors.phone3}
                  error={errors.phone3?.message}
                  type="tel"
                  margin="0px"
                  mb="10px"
                  register={register("phone3", {
                    pattern: {
                      value:
                        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                      message: "Błędny numer telefonu",
                    },
                  })}
                />
              </Flex>
              <Flex flexWrap={["wrap", "wrap", "nowrap"]} flexDirection="row">
                <CustomedInput
                  label="E-mail dla faktur"
                  extraLabel="Opcjonalne"
                  // placeholder="email@adres.pl"
                  // placeholder={data?.email_invoices || ""}
                  value={emailValue.invoicesEmail}
                  onChange={(text) => {
                    setEmailValue({ ...emailValue, invoicesEmail: text.target.value })
                  }}
                  type="email"
                  margin="0px"
                  mb="10px"
                  register={register("email_invoices")}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex
                justifyContent="center"
                w="100%"
                flexDir={["column-reverse", "column-reverse", "row"]}
              >
                {!isSuccess && (
                  <Button
                    backgroundColor="lightPrimary"
                    _hover={{ backgroundColor: "lighterPrimary" }}
                    color="white"
                    p="10px 30px"
                    size="sm"
                    h="35px"
                    isDisabled={
                      Object.keys(
                        Object.fromEntries(
                          Object.entries(watch())
                            .filter(([_, v]) => v !== "")
                            .filter(([_, v]) => v !== undefined)
                        )
                      ).length < 1
                    }
                    type="submit"
                    isLoading={isLoading}
                  >
                    Zapisz
                  </Button>
                )}
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
