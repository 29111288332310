import { Button, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Card } from "../../Card";

import messagesImage from "../../../../img/messages/messages.png";
import { CustomCardImage } from "../../Card/chakra";

export interface IMessagesCard {
  messages: number;
}

export const MessagesCard: FC<IMessagesCard> = ({ messages }) => (
  <Card>
    <Flex flexDir="column" zIndex={2}>
      <Text fontSize="14px" color="lightGray" textTransform="uppercase">
        Nieprzeczytane wiadomości
      </Text>
      <Text
        fontSize="50px"
        fontWeight="600"
        color="lightPrimary"
        lineHeight="55px"
        ml={"30px"}
      >
        {messages}
      </Text>
    </Flex>
    <Button size="sm" rightIcon={<FaAngleRight />}>
      {"Sprawdź"}
    </Button>
    <CustomCardImage src={messagesImage} h="120px" right="-27px" />
  </Card>
);
