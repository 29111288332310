import {cloneElement, FC, ReactElement} from "react";
import QRCode from "react-qr-code";
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import {CustomedButton} from "../CustomedButton";

export interface IESim {
    qrCode?: string;
    button?: ReactElement;
}

export const ESim: FC<IESim> = ({
                                    qrCode,
                                    button
                                }) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <>
            {button ? (
                cloneElement(button, {onClick: onOpen})
            ) : (
                <CustomedButton
                    centered={true}
                    fontSize="14px"
                    height="35px"
                    label="Kontakt"
                    onClick={onOpen}
                />
            )}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay onClick={onClose}/>
                <ModalContent
                    minW={["100%", "100%", "256px"]}
                    minH={["100%", "100%", "initial"]}
                    m={["0px", "0px", "60px 0px"]}
                    borderRadius={["0px", "0px", "10px"]}
                >
                    <ModalHeader color="darkPrimary">
                        Instalacja karty E-SIM
                    </ModalHeader>
                    <ModalCloseButton
                        fontSize={["20px", "20px", "12px"]}
                        m={["10px", "10px", "0px"]}
                    />
                    <ModalBody minHeight="320px">
                        <h1 className={'qrCodeInfo'}>
                            Zeskanuj poniższy kod QR aby zainstalować kartę e-sim
                        </h1>
                        <QRCode
                            size={256}
                            style={{height: "auto", maxWidth: "100%", width: "100%", marginBottom: "20px"}}
                            value={qrCode || ''}
                            viewBox={`0 0 256 256`}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
