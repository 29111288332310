import { chakra } from "@chakra-ui/react";

export const CustomHeader = chakra("div", {
  baseStyle: {
    width: "full",
    textAlign: "left",
    color: "black",
    fontSize: "18px",
    fontWeight: "600",
  },
});

export const CustomLabel = chakra("span", {
  baseStyle: {},
});
