import { FC, Fragment } from "react";
import { Table, Tr, Th, Td, Text, Flex, Box } from "@chakra-ui/react";
import {
  CustomedTableBody,
  CustomedTableHead,
  MobileTableBody,
  StatusBadge,
} from "../..";

export interface IInternetService {
  name: string;
  location: string;
  proxy: string;
  status: string;
}

export interface IInternetServiceTable {
  services: IInternetService[];
}

export const InternetServiceTable: FC<IInternetServiceTable> = ({
  services,
}) => (
  <Table variant="simple">
    <CustomedTableHead>
      <Tr>
        <Th>Usługa</Th>
        <Th>Lokalizacja</Th>
        <Th>Status</Th>
      </Tr>
    </CustomedTableHead>

    <CustomedTableBody>
      {services.length ? (
        services.map(({ name, location, proxy, status }) => (
          <Tr key={name + location}>
            <Td>
              <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                {name }
              </Text>
            </Td>
            <Td>
              <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                {location}
              </Text>
            </Td>
            <Td>
              <Box fontSize="14px" color="darkerPrimary" fontWeight="600">
                {Number(status) ? (
                  <StatusBadge status="active" label="Aktywna" />
                ) : (
                  <StatusBadge status="inactive" label="Nieaktywna" />
                )}
              </Box>
            </Td>
          </Tr>
        ))
      ) : (
        <Tr>
          <Td>Brak danych</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      )}
    </CustomedTableBody>

    <MobileTableBody>
      {services.length ? (
        services.map(({ name, location, proxy, status }) => (
          <Fragment key={name + "mobile"}>
            <Tr>
              <Td p="0px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    Usługa
                  </Text>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {name}
                  </Text>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td p="0px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    Lokalizacja
                  </Text>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {location}
                  </Text>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td p="0px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    Proxy
                  </Text>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {proxy}
                  </Text>
                </Flex>
              </Td>
            </Tr>

            <Tr>
              <Td p="0px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    Status
                  </Text>
                  <Text fontSize="14px" color="darkerPrimary" fontWeight="600">
                    {status}
                  </Text>
                </Flex>
              </Td>
            </Tr>
          </Fragment>
        ))
      ) : (
        <Tr>
          <Td>Brak danych</Td>
        </Tr>
      )}
    </MobileTableBody>
  </Table>
);
