import { FC } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

import { CustomHeader, CustomIcon, CustomServiceBox } from "./chakra";

import { navItems } from "../../../helpers/dataCategories/Navigation";
import { CustomCardImage } from "../../Card/chakra";
import { Card, ContactFormModal } from "../..";

import spraksImage from "../../../../img/services/Sparks.png";
import { useFirm } from "../../../../hooks/useFirm";

const userServices = navItems;

export const ServicesCard: FC = () => {
  const { ebok_rights } = useFirm().data;
  const navigate = useNavigate();

  return (
    <Flex flexDir="column" w={"full"} h="full">
      <CustomHeader>Twoje usługi</CustomHeader>
      <Flex flexDir="column" h="full">
        <Flex mb="25px" flexDir="row">
          {userServices
            .filter(({ id }) => ebok_rights?.includes(id))
            .map(({ label, href, icon }) => (
              <CustomServiceBox
                key={label}
                onClick={() => navigate(`${href}`)}
                mr="10px"
                alignItems="center"
              >
                <CustomIcon>{icon}</CustomIcon>
                <Text fontSize="10px" color="black21" lineHeight="10px">
                  {label}
                </Text>
              </CustomServiceBox>
            ))}
        </Flex>

        <Card>
          <Flex flexDir="column" zIndex={2} h="full">
            <Box maxW="255px">
              <Text fontSize="14px" color="lightGray" textTransform="uppercase">
                Oferta
              </Text>
              <Text fontSize="14px" color="black21">
                Sprawdź nową ofertę, którą specjalnie dla Ciebie
                przygotowaliśmy.
              </Text>
            </Box>
            <ContactFormModal
              button={
                <Button
                  size="sm"
                  rightIcon={<FaAngleRight />}
                  mt="5px"
                  alignSelf="flex-start"
                >
                  Sprawdź
                </Button>
              }
              action="contract"
            />
          </Flex>
          <CustomCardImage src={spraksImage} height="102px" margin="10px" />
        </Card>
      </Flex>
    </Flex>
  );
};
