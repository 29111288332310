import axios from "axios";

export const axiosInstance = axios.create();

export const getJSON = (url: string) => axiosInstance.get(url);

export const deleteJSON = (url: string) => axiosInstance.delete(url);

export const downloadFromUrl = (url: string) =>
  axiosInstance
    .get(url, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((response) => {
      const filename = response.headers["content-disposition"]
        .split("=")[1]
        .replaceAll('"', "");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });

export const putJSON = (url: string, data: any) =>
  axiosInstance
    .put(url, data, {
      validateStatus: (status) => {
        return status < 500;
      },
    })
    .then((response) => {
      if (response.status >= 300) {
        return Promise.reject(response);
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const postJSON = (url: string, data: any) =>
  axiosInstance.post(url, data);

export const extendedPostJSON = (url: string, data: any) =>
  axiosInstance
    .post(url, data, {
      headers: { "Content-Type": "application/json" },
      validateStatus: (status) => {
        return status < 500;
      },
    })
    .then((response) => {
      if (response.status >= 300) {
        return Promise.reject(response);
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
