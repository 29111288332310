import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { secondsToMinutes } from "../shared/utils";
import { IQueryOptions } from "../core/types";
import { stringify } from "querystring";

export interface IBESummary {
  billsec_total: number;
  billsec_incoming: number;
  billsec_outgoing: number;
  billsec_free: number;
  total_netto: string;
  total_vat: string;
  total_brutto: string;
}

export const restructureSummaryData = (summary: IBESummary) => {
  if (summary) {
    return {
      billsec_total: secondsToMinutes(summary.billsec_total),
      billsec_incoming: secondsToMinutes(summary.billsec_incoming),
      billsec_outgoing: secondsToMinutes(summary.billsec_outgoing),
      billsec_free: secondsToMinutes(summary.billsec_free),
      total_netto: summary.total_netto + " zł",
      total_vat: summary.total_vat + " zł",
      total_brutto: summary.total_brutto + " zł",
    };
  }
  return {
    billsec_total: "00:00 min",
    billsec_incoming: "00:00 min",
    billsec_outgoing: "00:00 min",
    billsec_free: "00:00 min",
    total_netto: "0.00 zł",
    total_vat: "0.00 zł",
    total_brutto: "0.00 zł",
  };
};

const getBillingSummary = async (stringParams: string) =>
  getJSON(
    [endpoints.telephony.summary, stringParams && "?", stringParams].join("")
  );

export const useBillingSummary = ({ filters }: IQueryOptions) => {
  let urlParams = "";
  if (filters) urlParams += stringify(filters);

  const { data, isLoading, error } = useQuery(
    "billingSummary" + urlParams,
    () => getBillingSummary(urlParams),
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructureSummaryData(data?.data),
    error,
  };
};
