import {useQuery} from "react-query";
import {getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";

const getFirm = async () => getJSON(endpoints.user.firm);

export const useFirm = () => {
    const {data, isLoading, error} = useQuery("firm", getFirm, {
        staleTime: Infinity,
    });

    return {
        isLoading,
        data: data?.data || {},
        error,
    };
};
