import { useQuery } from "react-query";
import { getJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { formatPhoneNumber } from "../shared/utils";

interface IBEPhone {
  id: string;
  userid: string;
  accountcode: string;
  context: string;
  creationdate: string;
}

interface IBEPhones {
  list: IBEPhone[];
  total: string;
}

const getBillingPhones = async () => getJSON(endpoints.telephony.phones);

const restructurePhonesData = (data: IBEPhones) =>
  data
    ? data.list.map(({ accountcode }) => ({
        accountcode,
        phone: formatPhoneNumber(accountcode),
      }))
    : [];

export const useBillingPhones = () => {
  const { data, isLoading, error } = useQuery(
    "BillingPhones",
    getBillingPhones,
    {
      staleTime: Infinity,
    }
  );

  return {
    isLoading,
    data: restructurePhonesData(data?.data),
    error,
  };
};
