import {Flex, Box, Text} from '@chakra-ui/react';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, CustomedButton} from '../..';

import speedTestImage from '../../../../img/testSpeed/test_bg.png';
import {CustomCardImage} from '../../Card/chakra';

export interface ISpeedTestCard {}

export const SpeedTestCard: FC<ISpeedTestCard> = ({}) => {
    const navigate = useNavigate();

    return (
        <Card variant="dark">
            <Flex flexDir="column" display={["none", "none", "flex", "flex"]} zIndex={2} h="full">
                <Text fontSize="18px" color="white" textTransform="uppercase" zIndex={2} mt="10px">
                    Przetestuj swój internet
                </Text>
                <Text fontSize="14px" fontWeight="600" color="lightGray" mb="10px" zIndex={2}>
                    a my damy Ci więcej…
                </Text>
                <Box mt="auto">
                    <CustomedButton label="Przejdź do testu" onClick={() => navigate("/speedtest")}/>
                </Box>
            </Flex>

            <Flex display={["flex", "flex", "none", "none"]} flexDir="column">
                <Text fontSize="18px" color="white" textTransform="uppercase">
                    Przetestuj swój internet
                </Text>
                <Text fontSize="14px" fontWeight="600" color="lightGray" mb="10px">
                    a my damy Ci więcej…
                </Text>
                <CustomedButton label="Start" onClick={() => navigate("/speedtest")}/>
            </Flex>

            <CustomCardImage src={speedTestImage} h="full" right="-20px"/>
        </Card>
    );
};
