import { Flex, ListItem, UnorderedList, Text, List, Button, Box, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Portal, PopoverFooter } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { Card, Detail } from "../..";
import { useGlobalDamages } from "../../../../hooks/useGlobalDamages";
import { formatClock, restructureDate, timestampToDateString } from "../../../utils";
import { time } from "console";
import { CustomedButton } from "../..";

interface IGlobalDamage {
    id: string;
    firmid: string;
    name: string;
    type: string;
    status: string;
    damageno: string;
    year: string;
    date: string;
    userinfo: string;
    fixstarttime: string;
    fixfinishtime: string;
    creationdate: string;
}

interface IRestructuredDamages {
    id: string;
    name: string;
    type: string;
    description: string;
    reportedDate: string;
    plannedStartTime: string,
    plannedFinishTime: string,
}

const damageType = {
    DAMAGE: 'Usterka',
    PLAN: 'Prace'
}


export const restructureGlobalDamages = (damages: IGlobalDamage[]) => {
    return damages
        ? damages.map(
            ({ id, name, type, status, damageno, year, date, userinfo, fixstarttime, fixfinishtime, creationdate }) => ({
                id,
                name: name,
                type: type === 'damage' ? damageType.DAMAGE : damageType.PLAN,
                description: userinfo,
                reportedDate: timestampToDateString(Number(date) * 1000) + ' ' + formatClock(Number(date) * 1000),
                plannedStartTime: Number(fixstarttime) ? timestampToDateString(Number(fixstarttime) * 1000) + ' ' + formatClock(Number(fixstarttime) * 1000) : '',
                plannedFinishTime: Number(fixfinishtime) ? timestampToDateString(Number(fixfinishtime) * 1000) + ' ' + formatClock(Number(fixfinishtime) * 1000) : '',

            })
        )
        : [];
};



export const GlobalDamagesCard: FC = () => {

    const rawDamagesList = useGlobalDamages().data.list;
    const damages: IRestructuredDamages[] = restructureGlobalDamages(rawDamagesList);

    //logowanie dla testow
    // console.log('raw damages: ' + JSON.stringify(rawDamagesList))
    // console.log('damages: ' + JSON.stringify(damages))

    return (
        <Card title="Informacje" titleStyle={{ marginLeft: '5px' }} >
            <List >
                {damages.length ?
                    (
                        damages.map(({ id, name, description, type, reportedDate, plannedStartTime, plannedFinishTime }) => (
                            <Fragment key={id}>
                                <ListItem>
                                    <Popover placement='auto-start'>
                                        <PopoverTrigger>
                                            <Button
                                                backgroundColor="transparent"
                                                _hover={{ backgroundColor: "lighterPrimary" }}
                                                color="black"
                                                width={'100%'}
                                                height={'24px'}
                                                p={'0px 5px'}
                                            >
                                                <Text paddingRight={'5px'} width='75%' textAlign='start' fontSize='15px' fontWeight='normal' isTruncated >
                                                    {name}
                                                </Text>
                                                <Text width='25%' textAlign={'center'} fontSize='13px' fontWeight='normal' color='white' backgroundColor={type === damageType.DAMAGE ? 'red' : '#aabf0c'} borderRadius='5px' >
                                                    {type}
                                                </Text>
                                            </Button>
                                        </PopoverTrigger>
                                        <Portal>

                                            <PopoverContent borderWidth='2px' >
                                                <PopoverArrow />
                                                <PopoverCloseButton bg='#d4d4d4' />
                                                <PopoverHeader  >
                                                    <Text fontWeight='semibold' >{name}</Text>
                                                    <Flex flexDir={'row'} width={'100%'} >
                                                        <Text width={'50%'} fontSize={'12px'} >Data zgłoszenia</Text>
                                                        <Text width={'50%'} fontSize={'12px'} textAlign={'end'} >{reportedDate}</Text>
                                                    </Flex>
                                                </PopoverHeader>
                                                <PopoverBody maxH={'300px'} overflowY={'auto'}>{description}</PopoverBody>
                                                {(plannedStartTime || plannedFinishTime) ?
                                                    <PopoverFooter >
                                                        {plannedStartTime ?
                                                            <Flex flexDir={'row'} width={'100%'} >
                                                                <Text width={'50%'} fontSize={'12px'} >Planowana data rozpoczęcia</Text>
                                                                <Text width={'50%'} textAlign={'end'} fontSize={'12px'} >{plannedStartTime}</Text>
                                                            </Flex>
                                                            : null
                                                        }
                                                        {plannedFinishTime ?
                                                            <Flex flexDir={'row'} width={'100%'} >
                                                                <Text width={'50%'} fontSize={'12px'} >Planowana data zakończenia</Text>
                                                                <Text width={'50%'} textAlign={'end'} fontSize={'12px'} >{plannedFinishTime}</Text>
                                                            </Flex>
                                                            : null
                                                        }
                                                    </PopoverFooter>
                                                    : null
                                                }
                                            </PopoverContent>
                                        </Portal>
                                    </Popover>
                                </ListItem>
                            </Fragment>
                        ))
                    ) : null
                }
            </List>
        </Card>
    );
};
